import { Component, OnInit, Inject, PLATFORM_ID, Optional } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { SeoService } from '../Generales/servicios/seo.service';
@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css'],
})
export class ErrorComponent implements OnInit {
  s_Url = '';
  base_Href: any;
  constructor(private route: ActivatedRoute,
    private ats_Router: Router,
    private ats_SEO: SeoService,
    @Optional() @Inject(RESPONSE) private response: any) { }

  ngOnInit() {
    console.log();
    // this.response.statusCode = 404;
    // this.response.statusMessage = '404 - Page Not Found';
    this.base_Href = this.ats_SEO.cf_GetUrl();
    this.s_Url = this.base_Href + this.ats_Router.url;
  }

}
