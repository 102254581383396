import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-modal-evento',
  templateUrl: './modalimagen.html',
})
export class ModalImagenComponent implements OnInit {
  s_UrlImagen: string;
  constructor(
    public dialogRef: MatDialogRef<ModalImagenComponent>,
    @Inject(MAT_DIALOG_DATA) public ae_Datos: any
  ) {}

  ngOnInit() {
    if (this.ae_Datos && this.ae_Datos.s_UrlImagen !== '') {
      this.s_UrlImagen = this.ae_Datos.s_UrlImagen;
    }
  }
}
