/*
 * Copyright © 2012-2019 Grupo Frisa and its subsidiaries.
 * Version Generador: 2.0.4.0 - Angular TypeScript
 */

/**
 * **Acciones para un DTO**

 * Valores: 
 * - 0 = NotModified
 * - 1 = Modified
 * - 2 = New
 * - 4 = NewDeleted
 * - 5 = Deleted
 */
export enum DTORecordState {
    NotModified = 0,
    Modified = 1,
    New = 2,
    //New = 3,
    NewDeleted = 4,
    Deleted = 5
}

////////////////////////////////////////////////////////////////////////////////////////////////////
////Copyright © 2012-2014 Grupo Frisa and its subsidiaries.
////Version Generador: 2.0.3.2 - Visual Studio.Net (C#) 
////////////////////////////////////////////////////////////////////////////////////////////////////
////Modificaciones:
////    XXX - 20181101 - Creacion de la Clase
////    
////    
////    
////    
////    
////    
////    
////    
////    
////    
////    
////    
////////////////////////////////////////////////////////////////////////////////////////////////////
/**
 * **BaseDTO**
 * 
 * Propiedades: 
 * - DTOState : *DTORecordState*
 * - l_Index : *number*
 * - Tag : *string*
 * - dt_FechaModificacion : *Date*
 * - i_IdUsuarioModificacion : *number*
 * - s_Status : *string*
 */
export class BaseDTO {
    //private _recordState: DTORecordState = DTORecordState.NotModified;
    public DTOState: DTORecordState;

    public Tag: string;
    public l_Index: number;
    public s_JsonObject: string;

    public dt_FechaModificacion: Date;
    public i_IdUsuarioModificacion: number;
    public s_Status: string;

    // get DTOState() {
    //     return this._recordState;
    // }

    // set DTOState(NewDTOState) {
    //     this._recordState = NewDTOState;

    //     switch (NewDTOState) {
    //         case DTORecordState.NotModified: {
    //             this._recordState = DTORecordState.NotModified;
    //             break;
    //         }
    //         case DTORecordState.Modified: {
    //             if (this._recordState === DTORecordState.NotModified || this._recordState === DTORecordState.Deleted) {
    //                 this._recordState = DTORecordState.NotModified;
    //             }
    //             break;
    //         }
    //         case DTORecordState.Deleted: {
    //             if (this._recordState === DTORecordState.New || this._recordState === DTORecordState.NewDeleted) {
    //                 this._recordState = DTORecordState.NewDeleted;
    //             }

    //             else if (this._recordState === DTORecordState.Modified || this._recordState === DTORecordState.NotModified) {
    //                 this._recordState = DTORecordState.Deleted;
    //             }
    //             break;
    //         }
    //         case DTORecordState.New: {
    //             if (this._recordState === DTORecordState.NotModified || this._recordState === DTORecordState.NewDeleted) {
    //                 this._recordState = DTORecordState.New;
    //             }
    //             break;
    //         }
    //         default: {
    //             break;
    //         }
    //     }
    // }
    /**
     * **constructor**
     *
     * Esta funcion no deberia de ser SobreEscrita.
     *
     * 	Crea un DTO e inicializa los atributos
     */
    constructor() {
        //this._recordState = DTORecordState.NotModified;
        this.DTOState = DTORecordState.NotModified;
        this.l_Index = 0;
        this.Tag = '';
        this.s_JsonObject = '';

        this.dt_FechaModificacion = new Date(1900, 1, 1);
        this.i_IdUsuarioModificacion = 0;
        this.s_Status = 'A';
    }
    /**
     * **cf_Clone**
     *
     * Esta funcion no deberia de ser SobreEscrita.
     *
     * 	Clona el DTO (mismos atributos y estado), regresa un DTO de tipo BaseDTO.
     * @returns BaseDTO
     */
    public cf_Clone(): BaseDTO {
        let lDT_New: BaseDTO;

        lDT_New = new BaseDTO();

        return lDT_New;
    }

    public e_CloneAttributes(aDTO_Original: BaseDTO) {
        this.DTOState = aDTO_Original.DTOState;
        this.l_Index = aDTO_Original.l_Index;
        this.Tag = aDTO_Original.Tag;
        this.s_JsonObject = aDTO_Original.s_JsonObject;

        this.dt_FechaModificacion = aDTO_Original.dt_FechaModificacion;
        this.i_IdUsuarioModificacion = aDTO_Original.i_IdUsuarioModificacion;
        this.s_Status = aDTO_Original.s_Status;

    }
}
