﻿/*
 * Copyright © 2012-2021 Grupo Frisa and its subsidiaries.
 * Version Generador: 2.3.4.0 - Angular TypeScript
 */

import { BaseDTO } from '../BaseClasses/Pattern/BaseDTO';

import { PlazaDTO } from './PlazaDTO';

/**
 * **SitioWebDTO**

 * Llave Primaria: 
 * - i_IdSitioWeb

 * Dependientes (DTO): 
 * - aDTO_Plaza
 */
export class SitioWebDTO extends BaseDTO {
    public i_IdSitioWeb: number;
    public i_IdTipoSitioWeb: number;
    public s_Nombre: string;
    public s_UrlBase: string;
    public i_IdImagenLogoPrincipal: number;
    public i_IdPlantillaHome: number;
    public i_IdPlantillaTiendas: number;
    public i_IdPlantillaEntretenimiento: number;
    public i_IdPlantillaRestaurantes: number;
    public i_IdPlantillaNovedades: number;
    public i_IdPlantillaEventos: number;
    public i_IdPlantillaPromociones: number;
    public i_IdPlantillaRentaEspacios: number;
    public i_IdPlantillaNosostros: number;
    public i_IdRedSocial01: number;
    public i_IdRedSocial02: number;
    public i_IdRedSocial03: number;
    public i_IdRedSocial04: number;
    public s_CeoReservado1: string;
    public s_CeoReservado2: string;
    public s_CeoReservado3: string;
    public s_CeoReservado4: string;

    /*
     * Entidades que dependen de SitioWebDTO
     */
    public aDTO_Plaza: PlazaDTO[];

    /**
     * **constructor**
     *
     * Crea los DTOs (FKs) de la instancia e inicializa atributos
     * @param ai_IdSitioWeb number
     * @param ab_SetupDTO Boolean
     * @returns void
     */
    constructor(ai_IdSitioWeb: number = 0, ab_SetupDTO: Boolean = true) {
        super();

        this.cf_CreateLevelDTO(ab_SetupDTO);

        if (ab_SetupDTO) { this.cf_SetupDTO(); }

        this.i_IdSitioWeb = ai_IdSitioWeb;
    }

    /**
     * **cf_CreateLevelDTO**
     *
     * Esta funcion no debe de ser SobreEscrita.
     *
     * Crea un DTO hasta el nivel solicitado (llaves foraneas).
     *
     * Inicializa sus propiedades si es que fue requerido.
     * @param ab_SetupDTO Indica si se inicializaran las variables del DTO
     * @returns void
     */
    public cf_CreateLevelDTO(ab_SetupDTO: Boolean): void {
    }

    /**
     * **cf_SetupDTO**
     *
     * 	Inicializa todos los atributos del DTO, excepto la PK
     * @returns void
     */
    public cf_SetupDTO(): void {
        this.i_IdTipoSitioWeb = 0;
        this.s_Nombre = '';
        this.s_UrlBase = '';
        this.i_IdImagenLogoPrincipal = 0;
        this.i_IdPlantillaHome = 0;
        this.i_IdPlantillaTiendas = 0;
        this.i_IdPlantillaEntretenimiento = 0;
        this.i_IdPlantillaRestaurantes = 0;
        this.i_IdPlantillaNovedades = 0;
        this.i_IdPlantillaEventos = 0;
        this.i_IdPlantillaPromociones = 0;
        this.i_IdPlantillaRentaEspacios = 0;
        this.i_IdPlantillaNosostros = 0;
        this.i_IdRedSocial01 = 0;
        this.i_IdRedSocial02 = 0;
        this.i_IdRedSocial03 = 0;
        this.i_IdRedSocial04 = 0;
        this.s_CeoReservado1 = '';
        this.s_CeoReservado2 = '';
        this.s_CeoReservado3 = '';
        this.s_CeoReservado4 = '';
    }

    /**
     * **cf_Clone**
     *
     * Esta funcion no deberia de ser SobreEscrita.
     *
     * 	Clona el DTO (mismos atributos y estado), regresa un DTO de tipo SitioWebDTO.
     * @returns SitioWebDTO
     */
    public cf_Clone(): BaseDTO {
        let lDT_New: SitioWebDTO;

        lDT_New = new SitioWebDTO(null, false);
        lDT_New.e_CloneAttributes(this);

        return lDT_New;
    }

    /**
     * **cf_SamePK**
     *
     * Esta funcion no deberia de ser SobreEscrita.
     *
     * 	Revisa si la llave primaria de este DTO es igual a la del argumento (SitioWebDTO).
     * @param aDT_Entity El DTO contra el que se va a comparar la PK
     * @returns Boolean
     */
    public cf_SamePK(aDT_Entity: BaseDTO): Boolean {
        let lDT_Entity: SitioWebDTO;

        lDT_Entity = aDT_Entity as SitioWebDTO;

        if (this.i_IdSitioWeb === null || lDT_Entity.i_IdSitioWeb === null) return false;
        if (this.i_IdSitioWeb !== lDT_Entity.i_IdSitioWeb) return false;

        return true;
    }

    /**
     * **e_CloneAttributes**
     *
     * Este evento podria ser SobreCargado (en caso de que se declaren variables en el DTO).
     *
     * 	Copia los valores de todos los atributos del DTO Original a este que es el Clon
     * @param aDTO_Original SitioWebDTO
     * @returns Nada
     */
    public e_CloneAttributes(aDTO_Original: BaseDTO): void {
        let lDTO_Original: SitioWebDTO;

        super.e_CloneAttributes(aDTO_Original);

        lDTO_Original = aDTO_Original as SitioWebDTO;

        this.i_IdSitioWeb = lDTO_Original.i_IdSitioWeb;
        this.i_IdTipoSitioWeb = lDTO_Original.i_IdTipoSitioWeb;
        this.s_Nombre = lDTO_Original.s_Nombre;
        this.s_UrlBase = lDTO_Original.s_UrlBase;
        this.i_IdImagenLogoPrincipal = lDTO_Original.i_IdImagenLogoPrincipal;
        this.i_IdPlantillaHome = lDTO_Original.i_IdPlantillaHome;
        this.i_IdPlantillaTiendas = lDTO_Original.i_IdPlantillaTiendas;
        this.i_IdPlantillaEntretenimiento = lDTO_Original.i_IdPlantillaEntretenimiento;
        this.i_IdPlantillaRestaurantes = lDTO_Original.i_IdPlantillaRestaurantes;
        this.i_IdPlantillaNovedades = lDTO_Original.i_IdPlantillaNovedades;
        this.i_IdPlantillaEventos = lDTO_Original.i_IdPlantillaEventos;
        this.i_IdPlantillaPromociones = lDTO_Original.i_IdPlantillaPromociones;
        this.i_IdPlantillaRentaEspacios = lDTO_Original.i_IdPlantillaRentaEspacios;
        this.i_IdPlantillaNosostros = lDTO_Original.i_IdPlantillaNosostros;
        this.i_IdRedSocial01 = lDTO_Original.i_IdRedSocial01;
        this.i_IdRedSocial02 = lDTO_Original.i_IdRedSocial02;
        this.i_IdRedSocial03 = lDTO_Original.i_IdRedSocial03;
        this.i_IdRedSocial04 = lDTO_Original.i_IdRedSocial04;
        this.s_CeoReservado1 = lDTO_Original.s_CeoReservado1;
        this.s_CeoReservado2 = lDTO_Original.s_CeoReservado2;
        this.s_CeoReservado3 = lDTO_Original.s_CeoReservado3;
        this.s_CeoReservado4 = lDTO_Original.s_CeoReservado4;
    }
}
