import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { PlantillaDTO } from '../Common/Models/PlantillaDTO';
import { HttpClientService } from '../Common/Servicios/http-client.service';
import { SeccionDTO } from '../Common/Models/SeccionDTO';
import { Router } from '@angular/router';
import { SitioWebDTO } from '../Common/Models/SitioWebDTO';
import { isPlatformBrowser } from '@angular/common';
import { ImagenDTO } from '../Common/Models/ImagenDTO';
import { SeoService } from '../Generales/servicios/seo.service';
import { environment} from '../../environments/environment';

@Component({
  selector: 'app-tiendas',
  templateUrl: './tiendas.component.html'
})
export class TiendasComponent implements OnInit {
  aDTO_PlantillaTiendas: PlantillaDTO = null;
  aDTO_TiendaPrincipal: SeccionDTO = null;
  aDTO_TiendaDoble: SeccionDTO = null;
  aDTO_TiendaTriple: SeccionDTO = null;
  aDTO_SeccionBoton: SeccionDTO = null;
  s_hrefSEO: string;
  s_Url: string;
  aDTO_SitioWeb: SitioWebDTO = null;
  s_Basehref: any;
  constructor(private ats_HttCms: HttpClientService,
    private ats_Router: Router,
    @Inject(PLATFORM_ID) private platformId: string,
    private ats_SEO: SeoService,) {
    if (isPlatformBrowser(this.platformId)) {
      this.aDTO_SitioWeb = JSON.parse(sessionStorage.getItem('sitio'));
    }
  }

  ngOnInit() {
    this.s_Basehref = this.ats_SEO.cf_GetUrl();
    if (this.aDTO_SitioWeb !== null) {
     this.cf_setDatos();
    } else {
      this.aDTO_SitioWeb = new SitioWebDTO(environment.i_IdSitioweb);
      this.cf_Read();
    }
  }

  cf_CargaPaginaTienda() {
    const aParameters = {
      i_IdSitioWeb: environment.i_IdSitioweb
    };
    this.ats_HttCms.cf_ReadPC('PlantillaES', this.aDTO_PlantillaTiendas, 11, aParameters).subscribe(Item => {
      if (Item !== null) {
        this.aDTO_PlantillaTiendas = Item as PlantillaDTO;
        for (let i = 0; i < this.aDTO_PlantillaTiendas.aDTO_PlantillaSeccion.length; i++) {
          switch (this.aDTO_PlantillaTiendas.aDTO_PlantillaSeccion[i].DTO_Seccion.i_IdUbicacion) {
            case 1:
              this.aDTO_TiendaPrincipal = this.aDTO_PlantillaTiendas.aDTO_PlantillaSeccion[i].DTO_Seccion;
              break;
            case 2:
              this.aDTO_TiendaDoble = this.aDTO_PlantillaTiendas.aDTO_PlantillaSeccion[i].DTO_Seccion;
              break;
            case 3:
              this.aDTO_TiendaTriple = this.aDTO_PlantillaTiendas.aDTO_PlantillaSeccion[i].DTO_Seccion;
              break;
            case 4:
              this.aDTO_SeccionBoton = this.aDTO_PlantillaTiendas.aDTO_PlantillaSeccion[i].DTO_Seccion;
              break;
          }
        }
      }
      const s_ImagenRuta = `${this.s_Basehref}${this.aDTO_PlantillaTiendas.s_CeoReservado3}`;
      this.ats_SEO.cf_SEO(this.s_Basehref + this.ats_Router.url, this.aDTO_PlantillaTiendas.s_CeoReservado1, s_ImagenRuta, this.aDTO_PlantillaTiendas.s_CeoReservado2);
    });
  }


  cf_Read() {
    this.ats_HttCms.cf_ReadPC('SitioWebES', this.aDTO_SitioWeb).subscribe(Item => {
      if (Item !== null) {
        this.aDTO_SitioWeb = Item as SitioWebDTO;
        if (isPlatformBrowser(this.platformId)) {
        sessionStorage.setItem('sitio', JSON.stringify(this.aDTO_SitioWeb));
        }
        this.cf_setDatos();
      }
    });
  }

  cf_setDatos() {
    this.s_Url = this.ats_Router.url;
    switch (this.s_Url) {
      case '/tiendas':
        this.aDTO_PlantillaTiendas = new PlantillaDTO(this.aDTO_SitioWeb.i_IdPlantillaTiendas);
        break;
      case '/entretenimiento':
        this.aDTO_PlantillaTiendas = new PlantillaDTO(this.aDTO_SitioWeb.i_IdPlantillaEntretenimiento);
        break;
      case '/restaurantes':
        this.aDTO_PlantillaTiendas = new PlantillaDTO(this.aDTO_SitioWeb.i_IdPlantillaRestaurantes);
        break;
        case '/parques':
          this.aDTO_PlantillaTiendas = new PlantillaDTO(this.aDTO_SitioWeb.i_IdPlantillaTiendas);
          break;
          case '/disponibilidad':
            this.aDTO_PlantillaTiendas = new PlantillaDTO(this.aDTO_SitioWeb.i_IdPlantillaEntretenimiento);
            break;
      default:
        break;
    }
    this.cf_CargaPaginaTienda();
  }

  cf_AbrirTienda(aDTO_Tienda: ImagenDTO) {
    let aDTO_PlantillaTienda = new PlantillaDTO(aDTO_Tienda.i_IdPlantilla);
    const aParameters = {
      i_IdSitioWeb: environment.i_IdSitioweb
    };
    this.ats_HttCms.cf_ReadPC('PlantillaES', aDTO_PlantillaTienda, 11, aParameters).subscribe(Item => {
      if (Item !== null) {
        aDTO_PlantillaTienda = Item as PlantillaDTO;
        let s_Url = '';
        switch (aDTO_PlantillaTienda.i_IdTipoPlantilla) {
          case 11:
            s_Url = `/${environment.s_Tipodetalle}/${aDTO_PlantillaTienda.s_UrlRelativa}`;
            break;
          case 13:
            s_Url = `/${environment.s_TipodetalleC}/${aDTO_PlantillaTienda.s_UrlRelativa}`;
            break;
          case 15:
            break;
          default:
            break;
        }
        this.ats_Router.navigateByUrl(s_Url);
      }
    });
  }
}
