﻿/*
 * Copyright © 2012-2021 Grupo Frisa and its subsidiaries.
 * Version Generador: 2.3.4.0 - Angular TypeScript
 */

import { BaseDTO } from '../BaseClasses/Pattern/BaseDTO';

import { PlazaDTO } from './PlazaDTO';

/**
 * **CiudadZonaDTO**

 * Llave Primaria: 
 * - i_IdCiudadZona

 * Dependientes (DTO): 
 * - aDTO_Plaza
 */
export class CiudadZonaDTO extends BaseDTO {
    public i_IdCiudadZona: number;
    public s_Ciudad: string;
    public s_Zona: string;
    public i_IdModxCiudad: number;
    public i_IdModxZona: number;
    public i_OrdenacionCiudad: number;
    public i_OrdenacionZona: number;
    public s_CiudadImagenPrincipal: string;
    public s_ZonaFiltro: string;
    public s_Reservado01: string;

    /*
     * Entidades que dependen de CiudadZonaDTO
     */
    public aDTO_Plaza: PlazaDTO[];

    /**
     * **constructor**
     *
     * Crea los DTOs (FKs) de la instancia e inicializa atributos
     * @param ai_IdCiudadZona number
     * @param ab_SetupDTO Boolean
     * @returns void
     */
    constructor(ai_IdCiudadZona: number = 0, ab_SetupDTO: Boolean = true) {
        super();

        this.cf_CreateLevelDTO(ab_SetupDTO);

        if (ab_SetupDTO) { this.cf_SetupDTO(); }

        this.i_IdCiudadZona = ai_IdCiudadZona;
    }

    /**
     * **cf_CreateLevelDTO**
     *
     * Esta funcion no debe de ser SobreEscrita.
     *
     * Crea un DTO hasta el nivel solicitado (llaves foraneas).
     *
     * Inicializa sus propiedades si es que fue requerido.
     * @param ab_SetupDTO Indica si se inicializaran las variables del DTO
     * @returns void
     */
    public cf_CreateLevelDTO(ab_SetupDTO: Boolean): void {
    }

    /**
     * **cf_SetupDTO**
     *
     * 	Inicializa todos los atributos del DTO, excepto la PK
     * @returns void
     */
    public cf_SetupDTO(): void {
        this.s_Ciudad = '';
        this.s_Zona = '';
        this.i_IdModxCiudad = 0;
        this.i_IdModxZona = 0;
        this.i_OrdenacionCiudad = 0;
        this.i_OrdenacionZona = 0;
        this.s_CiudadImagenPrincipal = '';
        this.s_ZonaFiltro = '';
        this.s_Reservado01 = '';
    }

    /**
     * **cf_Clone**
     *
     * Esta funcion no deberia de ser SobreEscrita.
     *
     * 	Clona el DTO (mismos atributos y estado), regresa un DTO de tipo CiudadZonaDTO.
     * @returns CiudadZonaDTO
     */
    public cf_Clone(): BaseDTO {
        let lDT_New: CiudadZonaDTO;

        lDT_New = new CiudadZonaDTO(null, false);
        lDT_New.e_CloneAttributes(this);

        return lDT_New;
    }

    /**
     * **cf_SamePK**
     *
     * Esta funcion no deberia de ser SobreEscrita.
     *
     * 	Revisa si la llave primaria de este DTO es igual a la del argumento (CiudadZonaDTO).
     * @param aDT_Entity El DTO contra el que se va a comparar la PK
     * @returns Boolean
     */
    public cf_SamePK(aDT_Entity: BaseDTO): Boolean {
        let lDT_Entity: CiudadZonaDTO;

        lDT_Entity = aDT_Entity as CiudadZonaDTO;

        if (this.i_IdCiudadZona === null || lDT_Entity.i_IdCiudadZona === null) return false;
        if (this.i_IdCiudadZona !== lDT_Entity.i_IdCiudadZona) return false;

        return true;
    }

    /**
     * **e_CloneAttributes**
     *
     * Este evento podria ser SobreCargado (en caso de que se declaren variables en el DTO).
     *
     * 	Copia los valores de todos los atributos del DTO Original a este que es el Clon
     * @param aDTO_Original CiudadZonaDTO
     * @returns Nada
     */
    public e_CloneAttributes(aDTO_Original: BaseDTO): void {
        let lDTO_Original: CiudadZonaDTO;

        super.e_CloneAttributes(aDTO_Original);

        lDTO_Original = aDTO_Original as CiudadZonaDTO;

        this.i_IdCiudadZona = lDTO_Original.i_IdCiudadZona;
        this.s_Ciudad = lDTO_Original.s_Ciudad;
        this.s_Zona = lDTO_Original.s_Zona;
        this.i_IdModxCiudad = lDTO_Original.i_IdModxCiudad;
        this.i_IdModxZona = lDTO_Original.i_IdModxZona;
        this.i_OrdenacionCiudad = lDTO_Original.i_OrdenacionCiudad;
        this.i_OrdenacionZona = lDTO_Original.i_OrdenacionZona;
        this.s_CiudadImagenPrincipal = lDTO_Original.s_CiudadImagenPrincipal;
        this.s_ZonaFiltro = lDTO_Original.s_ZonaFiltro;
        this.s_Reservado01 = lDTO_Original.s_Reservado01;
    }
}
