import { Directive, Input } from '@angular/core';

@Directive({
 selector: '[data-href]'
})
export class DataHrefModelDirective {

  @Input()
  'data-href': string;

  constructor() { }

}
