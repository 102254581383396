import { Component, OnInit, Input, ViewChild, ElementRef, OnDestroy, OnChanges, PLATFORM_ID, Inject } from '@angular/core';
import { interval, Observable, of } from 'rxjs';
import { SeccionDTO } from '../../Common/Models/SeccionDTO';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

interface Imagen {
  src: string;
  s_TextoPrincipal?: string;
  s_TextoSecundario?: string;
  s_TextoTercero?: string;
}
@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css'],
})
export class CarouselComponent implements OnInit, OnDestroy, OnChanges {
  @Input() slides: SeccionDTO = new SeccionDTO();
  @ViewChild('carousel') ahtml_Carousel: ElementRef;
  @ViewChild('textos') ahtml_Texto: ElementRef;
  @Input() tipo;
  a_Imagenes: Observable<Imagen[]> = of([]);
  currentSlide = 0;
  iHeight = 0;
  iMargin = 0;
  i_Tiempo = interval(5000);
  constructor(@Inject(PLATFORM_ID) private platformId: string) {
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
    this.i_Tiempo.subscribe(
      (number: number) => {
        // console.log('vez ejecutada???', number);
        const next = this.currentSlide + 1;
        this.currentSlide = next === this.slides.aDTO_SeccionImagen.length ? 0 : next;
      }
    );
    }
  }

  ngOnDestroy() {
  }

  ngOnChanges() {
    if (this.slides.aDTO_SeccionImagen !== undefined && this.slides.aDTO_SeccionImagen !== null
      && this.slides.aDTO_SeccionImagen.length > 0) {
      const aDTO_Imagenes = [];
      for (let i = 0; i < this.slides.aDTO_SeccionImagen.length; i++) {
        const le_Imagen: Imagen = {
          src: `${this.slides.aDTO_SeccionImagen[i].DTO_Imagen.s_Ubicacion}/${this.slides.aDTO_SeccionImagen[i].DTO_Imagen.s_Nombre}`
        };
        if (this.slides.s_TextoActivo === 'S') {
          le_Imagen.s_TextoPrincipal = `${this.slides.s_TextoPrincipal}`;
          le_Imagen.s_TextoTercero = `${this.slides.s_TextoAternativo02}`;
        } else if (this.slides.s_TextoActivo === 'D' && this.slides.aDTO_SeccionImagen[i].DTO_Imagen.s_TextoActivo === 'S') {
          le_Imagen.s_TextoPrincipal = `${this.slides.aDTO_SeccionImagen[i].DTO_Imagen.s_TextoPrincipal}`;

        } else if (this.slides.s_TextoActivo === 'A') {
          le_Imagen.s_TextoPrincipal = `${this.slides.s_TextoPrincipal}`;
          le_Imagen.s_TextoSecundario = `${this.slides.aDTO_SeccionImagen[i].DTO_Imagen.s_TextoPrincipal}`;
          le_Imagen.s_TextoTercero = `${this.slides.s_TextoAternativo02}`;
        }
        aDTO_Imagenes.push(le_Imagen);
      }
      this.a_Imagenes = of(aDTO_Imagenes);
      this.iHeight = 0 - this.ahtml_Carousel.nativeElement.offsetHeight + 50;
      this.iMargin = this.ahtml_Carousel.nativeElement.offsetHeight / 3;
      if (isPlatformBrowser(this.platformId)) {
        if (window.screen.width < 575) {
          this.iMargin = this.ahtml_Carousel.nativeElement.offsetHeight / 8;
        }
      }
    }
  }

  onPreviousClick() {
    const previous = this.currentSlide - 1;
    this.currentSlide = previous < 0 ? this.slides.aDTO_SeccionImagen.length - 1 : previous;
  }

  onNextClick() {
    const next = this.currentSlide + 1;
    this.currentSlide = next === this.slides.aDTO_SeccionImagen.length ? 0 : next;
  }

  cf_Selecciona(index: number) {
    this.currentSlide = index;
  }

  // cf_MuestraCuadros(index: number) {
  //   if (this.currentSlide === index || this.currentSlide === index + 1) {
  //     return true;
  //   }
  //   return false;
  // }
}
