import { Component, OnInit, PLATFORM_ID, Inject, SecurityContext } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ModalImagenComponent } from '../Generales/modales/imgmodal/modalimagen';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClientService } from '../Common/Servicios/http-client.service';
import { isPlatformBrowser } from '@angular/common';
import { PlantillaDTO } from '../Common/Models/PlantillaDTO';
import { SeccionDTO } from '../Common/Models/SeccionDTO';
import { SeccionImagenDTO } from '../Common/Models/SeccionImagenDTO';
import { SeoService } from '../Generales/servicios/seo.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-galeria',
  templateUrl: './galeria.component.html'
})
export class GaleriaComponent implements OnInit {
  tiles = [];
  a_videos = [];
  aDTO_PlantillaGaleria: PlantillaDTO = new PlantillaDTO(24);
  aDTO_Banner: SeccionDTO = new SeccionDTO(0);
  aDTO_Imagen: SeccionDTO = new SeccionDTO(1);
  aDTO_Videos: SeccionImagenDTO[];
  // aDTO_Banner: SeccionImagenDTO[] = null;
  breakpoint: number;
  s_Basehref: string;

  constructor(public dialog: MatDialog,
    private ats_HttpCms: HttpClientService,
    private ats_SEO: SeoService,
    private ats_Router: Router,
    @Inject(PLATFORM_ID) private platformId: Object,
    private ats_DomSanitizer: DomSanitizer) { }

  ngOnInit() {
    this.s_Basehref = this.ats_SEO.cf_GetUrl();
    if (isPlatformBrowser(this.platformId)) {
      this.breakpoint = (window.innerWidth <= 600) ? 2 : 8;
    }
    this.cf_ReadPlantilla();
  }

  onResize(event) {
    if (isPlatformBrowser(this.platformId)) {
      this.breakpoint = (event.target.innerWidth <= 600) ? 2 : 8;
    }
  }

  cf_Galeria(aDTO_Imagen: SeccionDTO) {
    const arreglo = [];
    for (let i = 0; i < aDTO_Imagen.aDTO_SeccionImagen.length; i++) {
      const Imagen = `${aDTO_Imagen.aDTO_SeccionImagen[i].DTO_Imagen.s_Ubicacion}/${aDTO_Imagen.aDTO_SeccionImagen[i].DTO_Imagen.s_Nombre}`;
      const Imagenes = {
        text: i, cols: 2,
        rows: Math.round(Math.random() + 1), color: `url("${Imagen}")`, imagen: Imagen
      };
      arreglo.push(Imagenes);
    }
    this.tiles = arreglo;
  }


  cf_ReadPlantilla() {
    const aParameters = {
      i_IdSitioWeb: environment.i_IdSitioweb
    };
    this.ats_HttpCms.cf_ReadPC('PlantillaES', this.aDTO_PlantillaGaleria, 11,aParameters).subscribe(Item => {
     if (Item !== null) {
        this.aDTO_PlantillaGaleria = Item as PlantillaDTO;
        for (let i = 0; i < this.aDTO_PlantillaGaleria.aDTO_PlantillaSeccion.length; i++) {
          switch (this.aDTO_PlantillaGaleria.aDTO_PlantillaSeccion[i].DTO_Seccion.i_IdUbicacion) {
            case 1:
              this.aDTO_Banner = this.aDTO_PlantillaGaleria.aDTO_PlantillaSeccion[i].DTO_Seccion;
              break;
            case 2:
              this.aDTO_Imagen = this.aDTO_PlantillaGaleria.aDTO_PlantillaSeccion[i].DTO_Seccion;
              this.cf_Galeria(this.aDTO_Imagen);
              break;
            case 3:
              this.aDTO_Videos = this.aDTO_PlantillaGaleria.aDTO_PlantillaSeccion[i].DTO_Seccion.aDTO_SeccionImagen;
              this.cf_UrlVideo();
              break;
            default:
              break;
          }
        }
        const s_ImagenRuta = `${this.s_Basehref}${this.aDTO_PlantillaGaleria.s_CeoReservado3}`;
        this.ats_SEO.cf_SEO(this.s_Basehref + this.ats_Router.url,
          this.aDTO_PlantillaGaleria.s_CeoReservado1, s_ImagenRuta, this.aDTO_PlantillaGaleria.s_CeoReservado2);
      }
    });
  }

  cf_AbrirImagen(s_UrlImagen: string): void {
    const dialogRef = this.dialog.open(ModalImagenComponent, {
      width: 'auto',
      maxWidth: '800px',
      height: 'auto',
      data: { s_UrlImagen: s_UrlImagen }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  cf_UrlVideo() {
    for (let i = 0; i < this.aDTO_Videos.length; i++) {
      const s_VideoUrl = this.ats_DomSanitizer
      .bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + this.aDTO_Videos[i].DTO_Imagen.s_UrlValor);
      this.a_videos.push(s_VideoUrl);
    }
    // const s_Url = 'https://www.youtube.com/embed/' + s_Urlvideo;
    // return this.ats_DomSanitizer.bypassSecurityTrustResourceUrl( s_Url);
  }
}
