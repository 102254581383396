import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClientService } from '../Common/Servicios/http-client.service';
import { PlantillaDTO } from '../Common/Models/PlantillaDTO';
import { SeccionDTO } from '../Common/Models/SeccionDTO';
import { SeoService } from '../Generales/servicios/seo.service';
import { SitioWebDTO } from '../Common/Models/SitioWebDTO';
import { isPlatformBrowser } from '@angular/common';
import { environment} from '../../environments/environment';
import { MatDialog } from '@angular/material';
import { ModalEventoComponent } from '../Generales/modales/eventomodal/modalevento';

@Component({
  selector: 'app-promociones',
  templateUrl: './promociones.component.html'
})
export class PromocionesComponent implements OnInit {
  href: string;
  aDTO_PlantillaPromociones: PlantillaDTO = new PlantillaDTO();
  aDTO_Principal: SeccionDTO = null;
  aDTO_SitioWeb: SitioWebDTO = null;
  s_Basehref: string = '';

  constructor(
    private ats_Router: Router,
    private ats_HttpCms: HttpClientService,
    @Inject(PLATFORM_ID) private platformId: string,
    private ats_SEO: SeoService,
    public dialog: MatDialog) {
    if (isPlatformBrowser(this.platformId)) {
      this.aDTO_SitioWeb = JSON.parse(sessionStorage.getItem('sitio'));
    }
  }

  ngOnInit() {
    this.s_Basehref = this.ats_SEO.cf_GetUrl();
    if (this.aDTO_SitioWeb !== null) {
      this.aDTO_PlantillaPromociones = new PlantillaDTO(this.aDTO_SitioWeb.i_IdPlantillaPromociones);
      this.cf_ReadPlantilla();
    } else {
      this.aDTO_SitioWeb = new SitioWebDTO(environment.i_IdSitioweb);
      this.cf_Read();
    }
  }

  cf_ReadPlantilla() {
    const aParameters = {
      i_IdSitioWeb: environment.i_IdSitioweb
    };
    this.ats_HttpCms.cf_ReadPC('PlantillaES', this.aDTO_PlantillaPromociones, 11, aParameters).subscribe(Item => {
      if (Item !== null) {
        this.aDTO_PlantillaPromociones = Item as PlantillaDTO;
        for (let i = 0; i < this.aDTO_PlantillaPromociones.aDTO_PlantillaSeccion.length; i++) {
          switch (this.aDTO_PlantillaPromociones.aDTO_PlantillaSeccion[i].DTO_Seccion.i_IdUbicacion) {
            case 1:
              this.aDTO_Principal = this.aDTO_PlantillaPromociones.aDTO_PlantillaSeccion[i].DTO_Seccion;
              break;
          }
        }
        const s_ImagenRuta = `${this.s_Basehref}${this.aDTO_PlantillaPromociones.s_CeoReservado3}`;
        this.ats_SEO.cf_SEO(this.s_Basehref + this.ats_Router.url,
          this.aDTO_PlantillaPromociones.s_CeoReservado1, s_ImagenRuta, this.aDTO_PlantillaPromociones.s_CeoReservado2);
      }
    });
  }

  cf_Read() {
    this.ats_HttpCms.cf_ReadPC('SitioWebES', this.aDTO_SitioWeb).subscribe(Item => {
      if (Item !== null) {
        this.aDTO_SitioWeb = Item as SitioWebDTO;
        if (isPlatformBrowser(this.platformId)) {
          sessionStorage.setItem('sitio', JSON.stringify(this.aDTO_SitioWeb));
        }
        this.aDTO_PlantillaPromociones = new PlantillaDTO(this.aDTO_SitioWeb.i_IdPlantillaPromociones);
        this.cf_ReadPlantilla();
      }
    });
  }

  cf_AbrirPromocion(i_IdSeccion: number): void {
    const dialogRef = this.dialog.open(ModalEventoComponent, {
      width: '80%',
      maxWidth: '1200px',
      height: 'auto',
      maxHeight: '80vh',
      data: {i_IdSeccion: i_IdSeccion, s_Tipo: 'promocion'}
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

}
