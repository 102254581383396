// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
    production: true,
    baseUrl: 'https://magnocentro26.mx/',
    i_IdPlaza: 99,
    i_IdSitioweb: 22,
    s_NombreSitio: 'Magnocentro',
    s_UbicacionFooter: 'Blvd. Magnocentro N° 26,<br>Huixquilucan de Degollado <br> Centro (Interlomas), Huixquilucan, <br> Estado de México, C.P. 52760',
    s_RedSocialInstagram: '<a href="https://www.instagram.com/magnocentrooficial/" target="_blank" rel="noopener noreferrer"><i class="fab fa-instagram mr-1"></i>magnocentrooficial</a>',
    s_RedSocialFacebook: '<a href="https://www.facebook.com/ShoppingMagnocentro/" target="_blank" rel="noopener noreferrer"><i class="fab fa-facebook mr-1"></i>Magnocentro</a>',
    s_RedSocialYoutube: '<a href="https://www.youtube.com/channel/UCJilUKQg1xiJ9jvp_xaEWDQ" target="_blank" rel="noopener noreferrer"><i class="fab fa-youtube mr-1"></i>Grupo Frisa</a>',
    a_MenuFooter: {
        b_Servicios: true,
        b_Preguntas:true,
        b_Mapa: true,
    },
    s_AcercaDe: `Acerca de Magnocentro`,
    s_MapaLink: `Mapa de Magnocentro`,
    s_Lat: '19.406876',
    s_Lng: '-99.272137',
    s_FbAppId: '720947348826492',
    s_RentaIntermediaPrincipal: 'assets/image/Plaza099-MAGNO26/Renta-de-espacios-plantilla-principal/RentaIntermedia_banner.jpg',
    s_RentaIntermediaArrenLo: 'assets/image/Plaza099-MAGNO26/Renta-de-espacios-plantilla-principal/RentaIntermedia_Renta-Espacios.png',
    s_RentaIntermediaEspacios: 'assets/image/Plaza099-MAGNO26/Renta-de-espacios-plantilla-principal/RentaItermedia_Espacios-Publicitarios.png',
    s_Sitio: 'Blvd. Magnocentro 26',
    s_MapaBanner: 'assets/image/Plaza099-MAGNO26/Mapa/Mapa_Banner1.png',
    s_MapaTexto: 'Magnocentro es un centro comercial en donde encontrarás diversión, además de moda, tecnología, entretenimiento para toda la familia.',
    s_PlanogramaRuta: 'assets/svgs/MAGNOCENTROB_NIVEL-1.html',
    i_IdPlantillaRentaLocales: 1004,
    i_IdPlantillaContacto: 1005,
    i_IdPlantillaPreguntas: 1006,
    s_ImagenLateralPreguntas: 'assets/image/Plaza099-MAGNO26/Preguntas-Frecuentes/Preguntas-Frecuentes_Imagen1.png',
    i_IdPlantillaServicios: 1010,
    i_IdPlantillaInformacion: 1012,
    s_SiteKeyCaptcha: '6Lem99wZAAAAAO29hn_PkEebOmzz3aRaESP8m-8h',
    e_DatosPlaza: {s_MedidaPrin: '47,924', s_MedidaSub: 'mts 2 rentables', s_VisitantesPrin: '7,200',
                    s_VisitantesSub: 'Millones de visitas al año', s_TiendasPrin: '143',
                    s_TiendasSub: 'Tiendas', s_HorarioPrin: '11AM - 8PM', s_HorarioSub: 'Horario lunes a domingo'},
    s_TextoMsj: 'Ven y Disfruta con tu familia visitando nuestras tiendas en MagnoCentro',
    s_Tags: 'Magnocentro, Frisa, Tiendas, Centro Comercial',
    a_Menu: [
      {s_Ruta:'/tiendas', s_NombreRuta:'Tiendas y Servicios'},
      {s_Ruta:'/entretenimiento', s_NombreRuta:'Entretenimiento'},
      {s_Ruta: '/restaurantes', s_NombreRuta:'Restaurantes'},
      {s_Ruta:'/eventos', s_NombreRuta:'Eventos'},
      {s_Ruta:'/novedades', s_NombreRuta:'Novedades'},
      {s_Ruta:'/promociones', s_NombreRuta:'Promociones'},
      {s_Ruta:'/renta-de-espacios', s_NombreRuta:'Renta de espacios'},
      {s_Ruta:'/informacion', s_NombreRuta:'Información principal'}
      ],
      s_Tipodetalle: 'tienda',
      s_TipodetalleC: 'tiendaC'

    // baseUrl: 'https://www.parquesindustrialesFrisa.com.mx/',
    // i_IdPlaza: 111,
    // i_IdSitioweb: 8,
    // s_NombreSitio: 'Parques industriales',
    // s_UbicacionFooter: 'Oficinas corporativas: <br> Blvd. Magnocentro N° 26,<br>Huixquilucan de Degollado <br> Centro (Interlomas), Huixquilucan, <br> Estado de México, C.P. 52760',
    // s_RedSocialInstagram: '<a href="https://www.instagram.com/frisaparquesindustriales/?hl=es-la" target="_blank" rel="noopener noreferrer"><i class="fab fa-instagram mr-1"></i>frisaparquesindustriales</a>',
    // s_RedSocialFacebook: '<a href="https://www.facebook.com/FRISA-Parques-Industriales-201307090460451" target="_blank" rel="noopener noreferrer"><i class="fab fa-facebook mr-1"></i>FRISA Parques Industriales</a>',
    // s_RedSocialYoutube: '',
    // a_MenuFooter: {
    //     b_Servicios: false,
    //     b_Preguntas:false,
    //     b_Mapa: false,
    // },
    // s_Lat: '19.406876',
    // s_Lng: '-99.272137',
    // s_FbAppId: '',
    // s_AcercaDe: 'Acerca de parques industriales',
    // s_MapaLink: ``,
    // s_MapaTexto: '',
    // s_RentaIntermediaPrincipal: '',
    // s_RentaIntermediaArrenLo: '',
    // s_RentaIntermediaEspacios: '',
    // s_Sitio:'Grupo Frisa',
    // s_MapaBanner: '',
    // s_PlanogramaRuta: '',
    // i_IdPlantillaRentaLocales: 0,
    // i_IdPlantillaContacto: 5005,
    // i_IdPlantillaPreguntas: 5011,
    // s_ImagenLateralPreguntas: 'assets/image/Parques-Industriales/Preguntas-Frecuentes/Lateral_Preguntas-Frecuentes.jpg',
    // i_IdPlantillaServicios: 0,
    // i_IdPlantillaInformacion: 0,
    // s_SiteKeyCaptcha: '6Lc9t8IZAAAAACDdXO2pBpLGL_SDdyR3gG8T98i4',
    // e_DatosPlaza: {s_MedidaPrin: '', s_MedidaSub: '', s_VisitantesPrin: 0,
    //                 s_VisitantesSub: '', s_TiendasPrin: 0,
    //                 s_TiendasSub: '', s_HorarioPrin: '', s_HorarioSub: ''},
    // s_TextoMsj: 'Conoce nuestras naves industriales que se adaptan a tus necesidades ',
    // s_Tags: 'Parques Industriales, Frisa, Naves industriales',
    // a_Menu: [
    //     {s_Ruta:'/home', s_NombreRuta:'Home'},
    //     {s_Ruta:'/parques', s_NombreRuta:'Parques Industriales'},
    //     {s_Ruta:'/novedades', s_NombreRuta:'Nuevos Proyectos'},
    //     {s_Ruta:'/disponibilidad', s_NombreRuta:'Disponibilidad'},
    //     {s_Ruta:'/noticias', s_NombreRuta:'Noticias'},
    //     {s_Ruta:'/contacto', s_NombreRuta:'Contacto'},
    //     {s_Ruta:'/mapa', s_NombreRuta:'Ubícanos'},
    //     ],
    //   s_Tipodetalle: 'parque',
    //   s_TipodetalleC: 'parqueC'

    //****MUNDO E
    // baseUrl: 'https://www.mundoe.com.mx/',
    // i_IdPlaza: 98,
    // i_IdSitioweb: 21,
    // s_NombreSitio: 'MundoE',
    // s_UbicacionFooter: 'Blvd Manuel Ávila Camacho N° 1007 <br> San Lucas Tepetlacalco<br> Tlalnepantla, Estado de México<br> C.P. 54055',
    // s_RedSocialInstagram: '<a href="https://www.instagram.com/mundoe_oficial/" target="_blank" rel="noopener noreferrer"><i class="fab fa-instagram mr-1"></i>MundoE_Oficial</a>',
    // s_RedSocialFacebook: '<a href="https://www.facebook.com/MundoEOficial/" target="_blank" rel="noopener noreferrer"><i class="fab fa-facebook mr-1"></i>MundoEOficial</a>',
    // s_RedSocialYoutube: '<a href="https://www.youtube.com/channel/UCJilUKQg1xiJ9jvp_xaEWDQ" target="_blank" rel="noopener noreferrer"><i class="fab fa-youtube mr-1"></i>Grupo Frisa</a>',
    // a_MenuFooter: {
    //     b_Servicios: true,
    //     b_Preguntas:true,
    //     b_Mapa: true,
    // },
    // s_Lat: '19.524471655924625',
    // s_Lng: '-99.22671099912796',
    // s_FbAppId: '840004896738959',
    // s_AcercaDe: `Acerca de Mundo E`,
    // s_MapaLink: `Mapa de Mundo E`,
    // s_RentaIntermediaPrincipal: 'assets/image/Plaza098-MUNDOE/Renta-de-espacios-plantilla-principal/renta-espacios-banner1.jpg',
    // s_RentaIntermediaArrenLo: 'assets/image/Plaza098-MUNDOE/Renta-de-espacios-plantilla-principal/arrendamiento-locales.jpg',
    // s_RentaIntermediaEspacios: 'assets/image/Plaza098-MUNDOE/Renta-de-espacios-plantilla-principal/espacios-publicitarios.jpg',
    // s_Sitio:'Mundo E',
    // s_MapaBanner: 'assets/image/Plaza098-MUNDOE/Mapa/mapa-banner1-mundo-e.jpg',
    // s_MapaTexto: 'Mundo e es un centro comercial en donde encontrarás diversión, además de moda, tecnología, entretenimiento para toda la familia.',
    // s_PlanogramaRuta: 'assets/svgs/MUNDOE_NIVEL1.html',
    // i_IdPlantillaRentaLocales: 17,
    // i_IdPlantillaContacto: 18,
    // i_IdPlantillaPreguntas: 25,
    // s_ImagenLateralPreguntas: 'assets/image/Plaza098-MUNDOE/Preguntas-Frecuentes/preguntas-frecuentes.jpg',
    // i_IdPlantillaServicios: 21,
    // i_IdPlantillaInformacion: 262,
    // s_SiteKeyCaptcha: '6Lc9t8IZAAAAACDdXO2pBpLGL_SDdyR3gG8T98i4',
    // e_DatosPlaza: {s_MedidaPrin: '133,330', s_MedidaSub: 'mts 2 rentables', s_VisitantesPrin: '24',
    //                 s_VisitantesSub: 'Millones de visitas al año', s_TiendasPrin: '270',
    //                 s_TiendasSub: 'Tiendas', s_HorarioPrin: '11AM - 9PM', s_HorarioSub: 'Horario lunes a domingo'},
    // s_TextoMsj: 'Ven y Disfruta con tu familia visitando nuestras tiendas en mundo E',
    // s_Tags: 'MundoE,Frisa,tienda',
    // a_Menu: [
    //   {s_Ruta:'/tiendas', s_NombreRuta:'Tiendas y Servicios'},
    //   {s_Ruta:'/entretenimiento', s_NombreRuta:'Entretenimiento'},
    //   {s_Ruta: '/restaurantes', s_NombreRuta:'Restaurantes'},
    //   {s_Ruta:'/eventos', s_NombreRuta:'Eventos'},
    //   {s_Ruta:'/novedades', s_NombreRuta:'Novedades'},
    //   {s_Ruta:'/promociones', s_NombreRuta:'Promociones'},
    //   {s_Ruta:'/renta-de-espacios', s_NombreRuta:'Renta de espacios'},
    //   {s_Ruta:'/informacion', s_NombreRuta:'Información principal'}
    //   ],
    //   s_Tipodetalle: 'tienda',
    //   s_TipodetalleC: 'tiendaC'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
