import { Component, OnInit, Inject, PLATFORM_ID, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClientService } from '../Common/Servicios/http-client.service';
import { isPlatformBrowser } from '@angular/common';
import { SitioWebDTO } from '../Common/Models/SitioWebDTO';
import { PlantillaDTO } from '../Common/Models/PlantillaDTO';
import { SeccionDTO } from '../Common/Models/SeccionDTO';
import { SeoService } from '../Generales/servicios/seo.service';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { environment } from '../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html'
})
export class ContactoComponent implements OnInit, OnDestroy {
  href: string;
  ifg_Contacto: FormGroup;
  s_UrlActual: string = '';
  aDTO_SitioWeb: SitioWebDTO = null;
  aDTO_PlantillaContacto: PlantillaDTO = new PlantillaDTO(environment.i_IdPlantillaContacto);
  aDTO_Banner: SeccionDTO = new SeccionDTO();
  aDTO_ImagenContacto: SeccionDTO = new SeccionDTO();
  aDTO_Img:SeccionDTO = new SeccionDTO();
  s_Basehref: string = '';
  siteKey = '';
  s_TokenCaptcha: string = '';
  ib_deshabilita: boolean = false;
  i_WindowWidth = 0;
  b_MostrarSucces = false;
  b_MostrarWarn = false;
  // tslint:disable-next-line: max-line-length
  reg_Telefono = /^(\(\+?\d{2,3}\)[\*|\s|\-|\.]?(([\d][\*|\s|\-|\.]?){6})(([\d][\s|\-|\.]?){2})?|(\+?[\d][\s|\-|\.]?){8}(([\d][\s|\-|\.]?){2}(([\d][\s|\-|\.]?){2})?)?)$/;
  s_Lat: string;
  s_Lng: string;
  s_IframeUrl: any;
  s_MensajeError: string;

  constructor(
    private ats_HttpCms: HttpClientService,
    private ats_SEO: SeoService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: string,
    private reCaptchaV3Service: ReCaptchaV3Service,
    private ats_DomSanitizer: DomSanitizer) {
      this.cf_Formulario();
      if (isPlatformBrowser(this.platformId)) {
        this.i_WindowWidth = window.screen.width;
      }
    }

  ngOnInit() {
    this.siteKey = environment.s_SiteKeyCaptcha;
    this.cf_Formulario();
    this.cf_GenerarTokenCaptcha();
    this.s_Basehref = this.ats_SEO.cf_GetUrl();
    this.s_UrlActual = this.router.url;
    this.cf_ReadPlantillaContacto();
    this.s_Lat = environment.s_Lat;
    this.s_Lng = environment.s_Lng;
    this.s_IframeUrl = this.ats_DomSanitizer.bypassSecurityTrustResourceUrl(`https://www.google.com/maps/embed/v1/place?key=AIzaSyDyX5gIHJxMAprhQ12R8TovRn9rFOUfEIQ&center=${this.s_Lat},${this.s_Lng}&q=${environment.s_Sitio}`);
  }

  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId)) {
      const capctha = document.getElementsByClassName('grecaptcha-badge');
      const tabToDisplay = capctha.item(0) as HTMLElement;
      tabToDisplay.classList.add('d-none');
    }
  }

  cf_Formulario() {
    this.ifg_Contacto = new FormGroup({
      s_Nombre: new FormControl({value: '', disabled: false}, [Validators.required]),
      s_Email: new FormControl({value: '', disabled: false}, [Validators.required, this.v_ValidacionEmail]),
      s_Telefono: new FormControl({value: '', disabled: false} , [Validators.pattern(this.reg_Telefono)]),
      s_Comentario: new FormControl({value: '', disabled: false})
    });

  }
  v_ValidacionEmail(control: FormControl): { [s: string]: boolean } {
    if (/^[^@]+@[^@]+\.[a-zA-Z]{2,}$/.test(control.value)) {
      return null;
    } else if (control.value === null || control.value === '') {
      return null;
    } else {
      return {
        CorreoIncorrecto: true
      };
    }
  }

  cf_Enviar() {
    if (this.ifg_Contacto.valid) {
      this.ib_deshabilita = true;
      // if (this.ifg_Contacto.controls['b_terminos'].value) {
      let aDatos: {
        s_Nombre: string,
        s_Email: string,
        s_Telefono: string,
        s_Comentario: string,
        TokenCaptcha: string,
        i_IdSitioWeb: number,
        i_IdTipoFormulario: number
      } = {
        s_Nombre: '',
        s_Email: '',
        s_Telefono: '',
        s_Comentario: '',
        TokenCaptcha: this.s_TokenCaptcha,
        i_IdSitioWeb: environment.i_IdSitioweb,
        i_IdTipoFormulario: 1
      };
      Object.assign(aDatos, this.ifg_Contacto.value);
      const s_aDatos = JSON.stringify(aDatos);
      this.ats_HttpCms.cf_SubmitForm(s_aDatos).subscribe(Item => {
        if (Item !== null) {
          this.ifg_Contacto.reset();
          this.ifg_Contacto.markAsPristine();
          this.ifg_Contacto.markAsUntouched();
          Object.keys(this.ifg_Contacto.controls).forEach(key => {
            this.ifg_Contacto.get(key).setErrors(null);
          });
          this.b_MostrarSucces = true;
        }else {
          this.b_MostrarWarn = true;
          this.s_MensajeError = 'Algo salió mal, favor de intentarlo nuevamente. ';
        }
        this.cf_GenerarTokenCaptcha();
        this.ib_deshabilita = false;
      });
      // } else {
      // this.its_Toastr.error('Aceptar términos y condiciones de uso.');
      // this.cf_GenerarTokenCaptcha();
      // this.ib_deshabilita = false;
      // }
    } else {
      this.b_MostrarWarn = true;
      this.b_MostrarWarn = true;
      this.s_MensajeError = 'Favor de llenar correctamente los datos.';
      this.cf_GenerarTokenCaptcha();
    }
  }
  /**
     * **cf_GenerarTokenCaptcha**
     * Genera un token del servicio de recaptcha de google
     */
    cf_GenerarTokenCaptcha() {
      if (isPlatformBrowser(this.platformId)) {
        this.reCaptchaV3Service.execute(
          this.siteKey,
          'login',
          (token) => {
            this.s_TokenCaptcha = token;
            if (isPlatformBrowser(this.platformId)) {
              const capctha = document.getElementsByClassName('grecaptcha-badge');
              const tabToDisplay = capctha.item(0) as HTMLElement;
              tabToDisplay.classList.remove('d-none');
            }
          },
          {
            useGlobalDomain: false,
          }
        );
      }
    }
  
cf_ReadPlantillaContacto() {
  const aParameters = {
    i_IdSitioWeb: environment.i_IdSitioweb
  };
  this.ats_HttpCms.cf_ReadPC('PlantillaES', this.aDTO_PlantillaContacto, 11, aParameters).subscribe(Item => {
    if (Item !== null) {
      this.aDTO_PlantillaContacto = Item as PlantillaDTO;
      // this.aDTOEspacios = [];
      for (let i = 0; i < this.aDTO_PlantillaContacto.aDTO_PlantillaSeccion.length; i++) {
        switch (this.aDTO_PlantillaContacto.aDTO_PlantillaSeccion[i].DTO_Seccion.i_IdUbicacion) {
          case 1:
            this.aDTO_Banner = this.aDTO_PlantillaContacto.aDTO_PlantillaSeccion[i].DTO_Seccion;
            break;
          case 2:
              this.aDTO_ImagenContacto = this.aDTO_PlantillaContacto.aDTO_PlantillaSeccion[i].DTO_Seccion;
              break;
          case 3:
                this.aDTO_Img = this.aDTO_PlantillaContacto.aDTO_PlantillaSeccion[i].DTO_Seccion;
                 break;
          default:
            break;
        }
      }
      const s_ImagenRuta = `${this.s_Basehref}${this.aDTO_PlantillaContacto.s_CeoReservado3}`;
      this.ats_SEO.cf_SEO(this.s_Basehref + this.router.url, this.aDTO_PlantillaContacto.s_CeoReservado1,
        s_ImagenRuta, this.aDTO_PlantillaContacto.s_CeoReservado2);
    }
  });
}

}
