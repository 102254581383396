﻿/*
 * Copyright © 2012-2021 Grupo Frisa and its subsidiaries.
 * Version Generador: 2.3.4.0 - Angular TypeScript
 */

import { BaseDTO } from '../BaseClasses/Pattern/BaseDTO';
import { CiudadZonaDTO } from "./CiudadZonaDTO";
import { SitioWebDTO } from "./SitioWebDTO";

import { PlazaContratoDTO } from './PlazaContratoDTO';
import { PlazaGiroDTO } from './PlazaGiroDTO';

/**
 * **PlazaDTO**

 * Llave Primaria: 
 * - i_IdPlaza

 * Propiedades Fk (DTO): 
 * - DTO_SitioWeb.i_IdSitioWeb
 * - DTO_CiudadZona.i_IdCiudadZona

 * Dependientes (DTO): 
 * - aDTO_PlazaContrato
 * - aDTO_PlazaGiro
 */
export class PlazaDTO extends BaseDTO {
    public i_IdPlaza: number;
    public DTO_SitioWeb: SitioWebDTO;
    public DTO_CiudadZona: CiudadZonaDTO;
    public s_Nombre: string;
    public s_NombreCorto: string;
    public i_VisitasMensuales: number;
    public s_Direccion: string;
    public s_InformacionPlaza: string;
    public s_InformacionEmailContacto: string;
    public s_InformacionTelefonoContacto: string;
    public s_Horario: string;
    public s_Marcas: string;
    public s_LinkGoogleMaps: string;
    public s_CoordenadaLatitud: string;
    public s_CoordenadaLongitud: string;
    public s_UrlFacebook: string;
    public s_LinkMultiplaza: string;
    public s_AperturaNueva: string;
    public dc_MetrosTotales: number;
    public dc_MetrosRentables: number;
    public dt_FechaInauguracion: Date;
    public i_LocalesTotales: number;
    public i_LocalesDisponibles: number;
    public i_CajonesEstacionamiento: number;
    public s_NivelSocioEconomico: string;
    public s_EmailContacto: string;
    public s_TelefonoContacto: string;
    public i_IdRedSocial01: number;
    public i_IdRedSocial02: number;
    public i_IdRedSocial03: number;
    public i_Niveles: number;
    public s_FiltrosGiro: string;
    public s_CeoTitulo: string;
    public s_CeoKeyWords: string;
    public s_CeoDescripcion: string;
    public s_Reservado01: string;
    public s_Reservado02: string;
    public i_IdModxPlaza: number;
    public i_Ordenacion: number;

    /*
     * Entidades que dependen de PlazaDTO
     */
    public aDTO_PlazaContrato: PlazaContratoDTO[];
    public aDTO_PlazaGiro: PlazaGiroDTO[];

    /**
     * **constructor**
     *
     * Crea los DTOs (FKs) de la instancia e inicializa atributos
     * @param ai_IdPlaza number
     * @param ab_SetupDTO Boolean
     * @returns void
     */
    constructor(ai_IdPlaza: number = 0, ab_SetupDTO: Boolean = true) {
        super();

        this.cf_CreateLevelDTO(ab_SetupDTO);

        if (ab_SetupDTO) { this.cf_SetupDTO(); }

        this.i_IdPlaza = ai_IdPlaza;
    }

    /**
     * **cf_CreateLevelDTO**
     *
     * Esta funcion no debe de ser SobreEscrita.
     *
     * Crea un DTO hasta el nivel solicitado (llaves foraneas).
     *
     * Inicializa sus propiedades si es que fue requerido.
     * @param ab_SetupDTO Indica si se inicializaran las variables del DTO
     * @returns void
     */
    public cf_CreateLevelDTO(ab_SetupDTO: Boolean): void {
        if (ab_SetupDTO) {
            this.DTO_SitioWeb = new SitioWebDTO(null, false);
            this.DTO_CiudadZona = new CiudadZonaDTO(null, false);
        }
    }

    /**
     * **cf_SetupDTO**
     *
     * 	Inicializa todos los atributos del DTO, excepto la PK
     * @returns void
     */
    public cf_SetupDTO(): void {
        this.s_Nombre = '';
        this.s_NombreCorto = '';
        this.i_VisitasMensuales = 0;
        this.s_Direccion = '';
        this.s_InformacionPlaza = '';
        this.s_InformacionEmailContacto = '';
        this.s_InformacionTelefonoContacto = '';
        this.s_Horario = '';
        this.s_Marcas = '';
        this.s_LinkGoogleMaps = '';
        this.s_CoordenadaLatitud = '';
        this.s_CoordenadaLongitud = '';
        this.s_UrlFacebook = '';
        this.s_LinkMultiplaza = '';
        this.s_AperturaNueva = '';
        this.dc_MetrosTotales = 0;
        this.dc_MetrosRentables = 0;
        this.dt_FechaInauguracion = new Date(1900, 1, 1);
        this.i_LocalesTotales = 0;
        this.i_LocalesDisponibles = 0;
        this.i_CajonesEstacionamiento = 0;
        this.s_NivelSocioEconomico = '';
        this.s_EmailContacto = '';
        this.s_TelefonoContacto = '';
        this.i_IdRedSocial01 = 0;
        this.i_IdRedSocial02 = 0;
        this.i_IdRedSocial03 = 0;
        this.i_Niveles = 0;
        this.s_FiltrosGiro = '';
        this.s_CeoTitulo = '';
        this.s_CeoKeyWords = '';
        this.s_CeoDescripcion = '';
        this.s_Reservado01 = '';
        this.s_Reservado02 = '';
        this.i_IdModxPlaza = 0;
        this.i_Ordenacion = 0;
    }

    /**
     * **cf_Clone**
     *
     * Esta funcion no deberia de ser SobreEscrita.
     *
     * 	Clona el DTO (mismos atributos y estado), regresa un DTO de tipo PlazaDTO.
     * @returns PlazaDTO
     */
    public cf_Clone(): BaseDTO {
        let lDT_New: PlazaDTO;

        lDT_New = new PlazaDTO(null, false);
        lDT_New.e_CloneAttributes(this);

        return lDT_New;
    }

    /**
     * **cf_SamePK**
     *
     * Esta funcion no deberia de ser SobreEscrita.
     *
     * 	Revisa si la llave primaria de este DTO es igual a la del argumento (PlazaDTO).
     * @param aDT_Entity El DTO contra el que se va a comparar la PK
     * @returns Boolean
     */
    public cf_SamePK(aDT_Entity: BaseDTO): Boolean {
        let lDT_Entity: PlazaDTO;

        lDT_Entity = aDT_Entity as PlazaDTO;

        if (this.i_IdPlaza === null || lDT_Entity.i_IdPlaza === null) return false;
        if (this.i_IdPlaza !== lDT_Entity.i_IdPlaza) return false;

        return true;
    }

    /**
     * **e_CloneAttributes**
     *
     * Este evento podria ser SobreCargado (en caso de que se declaren variables en el DTO).
     *
     * 	Copia los valores de todos los atributos del DTO Original a este que es el Clon
     * @param aDTO_Original PlazaDTO
     * @returns Nada
     */
    public e_CloneAttributes(aDTO_Original: BaseDTO): void {
        let lDTO_Original: PlazaDTO;

        super.e_CloneAttributes(aDTO_Original);

        lDTO_Original = aDTO_Original as PlazaDTO;

        this.i_IdPlaza = lDTO_Original.i_IdPlaza;
        if (lDTO_Original.DTO_SitioWeb !== null) { this.DTO_SitioWeb = lDTO_Original.DTO_SitioWeb.cf_Clone() as SitioWebDTO; }
        if (lDTO_Original.DTO_CiudadZona !== null) { this.DTO_CiudadZona = lDTO_Original.DTO_CiudadZona.cf_Clone() as CiudadZonaDTO; }
        this.s_Nombre = lDTO_Original.s_Nombre;
        this.s_NombreCorto = lDTO_Original.s_NombreCorto;
        this.i_VisitasMensuales = lDTO_Original.i_VisitasMensuales;
        this.s_Direccion = lDTO_Original.s_Direccion;
        this.s_InformacionPlaza = lDTO_Original.s_InformacionPlaza;
        this.s_InformacionEmailContacto = lDTO_Original.s_InformacionEmailContacto;
        this.s_InformacionTelefonoContacto = lDTO_Original.s_InformacionTelefonoContacto;
        this.s_Horario = lDTO_Original.s_Horario;
        this.s_Marcas = lDTO_Original.s_Marcas;
        this.s_LinkGoogleMaps = lDTO_Original.s_LinkGoogleMaps;
        this.s_CoordenadaLatitud = lDTO_Original.s_CoordenadaLatitud;
        this.s_CoordenadaLongitud = lDTO_Original.s_CoordenadaLongitud;
        this.s_UrlFacebook = lDTO_Original.s_UrlFacebook;
        this.s_LinkMultiplaza = lDTO_Original.s_LinkMultiplaza;
        this.s_AperturaNueva = lDTO_Original.s_AperturaNueva;
        this.dc_MetrosTotales = lDTO_Original.dc_MetrosTotales;
        this.dc_MetrosRentables = lDTO_Original.dc_MetrosRentables;
        this.dt_FechaInauguracion = lDTO_Original.dt_FechaInauguracion;
        this.i_LocalesTotales = lDTO_Original.i_LocalesTotales;
        this.i_LocalesDisponibles = lDTO_Original.i_LocalesDisponibles;
        this.i_CajonesEstacionamiento = lDTO_Original.i_CajonesEstacionamiento;
        this.s_NivelSocioEconomico = lDTO_Original.s_NivelSocioEconomico;
        this.s_EmailContacto = lDTO_Original.s_EmailContacto;
        this.s_TelefonoContacto = lDTO_Original.s_TelefonoContacto;
        this.i_IdRedSocial01 = lDTO_Original.i_IdRedSocial01;
        this.i_IdRedSocial02 = lDTO_Original.i_IdRedSocial02;
        this.i_IdRedSocial03 = lDTO_Original.i_IdRedSocial03;
        this.i_Niveles = lDTO_Original.i_Niveles;
        this.s_FiltrosGiro = lDTO_Original.s_FiltrosGiro;
        this.s_CeoTitulo = lDTO_Original.s_CeoTitulo;
        this.s_CeoKeyWords = lDTO_Original.s_CeoKeyWords;
        this.s_CeoDescripcion = lDTO_Original.s_CeoDescripcion;
        this.s_Reservado01 = lDTO_Original.s_Reservado01;
        this.s_Reservado02 = lDTO_Original.s_Reservado02;
        this.i_IdModxPlaza = lDTO_Original.i_IdModxPlaza;
        this.i_Ordenacion = lDTO_Original.i_Ordenacion;
    }
}
