import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PrincipalComponent } from './principal/principal.component';
import { HomeComponent } from './home/home.component';
import { TiendasComponent } from './tiendas/tiendas.component';
import { NovedadesComponent } from './novedades/novedades.component';
import { MultimediaComponent } from './multimedia/multimedia.component';
import { EventosComponent } from './eventos/eventos.component';
import { PromocionesComponent } from './promociones/promociones.component';
import { DetalleTiendaBasicoComponent } from './detalle-tienda-basico/detalle-tienda-basico.component';
import { DetalleTiendaCompletoComponent } from './detalle-tienda-completo/detalle-tienda-completo.component';
import { ServiciosComponent } from './servicios/servicios.component';
import { RentaEspacioComponent } from './renta-espacio/renta-espacio.component';
import { ErrorComponent } from './error/error.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselComponent } from './Generales/carousel/carousel.component';
import { MaterialModule } from './app.material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PreguntasComponent } from './preguntas/preguntas.component';
import { BuscarTiendaComponent } from './buscar-tienda/buscar-tienda.component';
import { GaleriaComponent } from './galeria/galeria.component';
import { MapaComponent } from './mapa/mapa.component';
import { ModalEventoComponent } from './Generales/modales/eventomodal/modalevento';
import { ModalImagenComponent } from './Generales/modales/imgmodal/modalimagen';
import { DataHrefModelDirective } from './Generales/directivas/datahref';
import { PlanosComponent } from './planos/planos.component';
import { HttpClientModule } from '@angular/common/http';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { HttpErrorHandler } from './Common/Servicios/http-error-handler.service';
import { ContactoComponent } from './contacto/contacto.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { RentaIntermediaComponent } from './renta-intermedia/renta-intermedia.component';
import { FbButtonComponent } from './Generales/fb-button/fb-button.component';
import { InformacionComponent } from './informacion/informacion.component';
@NgModule({
  declarations: [
    AppComponent,
    PrincipalComponent,
    HomeComponent,
    TiendasComponent,
    NovedadesComponent,
    MultimediaComponent,
    EventosComponent,
    PromocionesComponent,
    DetalleTiendaBasicoComponent,
    DetalleTiendaCompletoComponent,
    ServiciosComponent,
    RentaEspacioComponent,
    ErrorComponent,
    CarouselComponent,
    PreguntasComponent,
    BuscarTiendaComponent,
    GaleriaComponent,
    MapaComponent,
    ModalEventoComponent,
    ModalImagenComponent,
    DataHrefModelDirective,
    PlanosComponent,
    ContactoComponent,
    RentaIntermediaComponent,
    FbButtonComponent,
    InformacionComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    NgxCaptchaModule,
  ],
  providers: [
    HttpErrorHandler
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    ModalEventoComponent,
    ModalImagenComponent
  ]
})
export class AppModule { }
