import { Component, OnInit, PLATFORM_ID, Inject, Output } from '@angular/core';
import { HttpClientService } from '../Common/Servicios/http-client.service';
import { PlantillaDTO } from '../Common/Models/PlantillaDTO';
import { SeccionDTO } from '../Common/Models/SeccionDTO';
import { isPlatformBrowser } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../environments/environment';
import { SeoService } from '../Generales/servicios/seo.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-preguntas',
  templateUrl: './preguntas.component.html',
  styleUrls: ['./preguntas.component.css']
})
export class PreguntasComponent implements OnInit {
  href: string;
  aDTO_PlantillaPreguntas: PlantillaDTO = new PlantillaDTO(environment.i_IdPlantillaPreguntas);
  aDTO_Banner: SeccionDTO = new SeccionDTO();
  aDTO_Imagen: SeccionDTO = new SeccionDTO();
  i_WindowWidth: number = 0;
  s_altoExpPanel: string;
  s_JsonLD: any;
  s_ImagenLateral = '';
  s_IframeUrl: any;
  s_Lng: string;
  s_Lat: string;
  s_Basehref: string = '';
  s_UrlActual: string = '';
  constructor(
    private ats_HttpCms: HttpClientService,
    @Inject(PLATFORM_ID) private platformId: string,
    private ats_DomSanitizer: DomSanitizer,
    private ats_SEO: SeoService,
    private router: Router) {
      if (isPlatformBrowser(this.platformId)) {
        this.i_WindowWidth = window.screen.width;
        if (this.i_WindowWidth < 574) {
          this.s_altoExpPanel = '80px';
        } else {
          this.s_altoExpPanel = '50px';
        }
      }
   }

  ngOnInit() {
    this.cf_ReadPreguntas();
    this.s_ImagenLateral = environment.s_ImagenLateralPreguntas;
    this.s_Lat = environment.s_Lat;
    this.s_Lng = environment.s_Lng;
    this.s_IframeUrl = this.ats_DomSanitizer.bypassSecurityTrustResourceUrl(`https://www.google.com/maps/embed/v1/place?key=AIzaSyDyX5gIHJxMAprhQ12R8TovRn9rFOUfEIQ&center=${this.s_Lat},${this.s_Lng}&q=${environment.s_Sitio}`);
  }

  cf_ReadPreguntas() {
    const aParameters = {
      i_IdSitioWeb: environment.i_IdSitioweb
    };
    this.ats_HttpCms.cf_ReadPC('PlantillaES', this.aDTO_PlantillaPreguntas, 11, aParameters).subscribe(Item => {
      if (Item !== null) {
        this.aDTO_PlantillaPreguntas = Item as PlantillaDTO;
        for (let i = 0; i < this.aDTO_PlantillaPreguntas.aDTO_PlantillaSeccion.length; i++) {
          switch (this.aDTO_PlantillaPreguntas.aDTO_PlantillaSeccion[i].DTO_Seccion.i_IdUbicacion) {
            case 1:
              this.aDTO_Banner = this.aDTO_PlantillaPreguntas.aDTO_PlantillaSeccion[i].DTO_Seccion;
              break;
            case 2:
              this.aDTO_Imagen = this.aDTO_PlantillaPreguntas.aDTO_PlantillaSeccion[i].DTO_Seccion;
              this.cf_PreguntasJson();
              break;
          }
        }
        const s_ImagenRuta = `${this.s_Basehref}${this.aDTO_PlantillaPreguntas.s_CeoReservado3}`;
        this.ats_SEO.cf_SEO(this.s_Basehref + this.router.url,
          this.aDTO_PlantillaPreguntas.s_CeoReservado1, s_ImagenRuta, this.aDTO_PlantillaPreguntas.s_CeoReservado2);
      }

    });
  }

  cf_PreguntasJson() {
      let s_Json = `<script type="application/ld+json">{
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity":
        [`;
        for (let i = 0; i < this.aDTO_Imagen.aDTO_SeccionImagen.length; i++) {
          if(  i === 4) {
            return '';
          }
          s_Json += `{
            "@type": "Question",
            "name": "${this.aDTO_Imagen.aDTO_SeccionImagen[i].DTO_Imagen.s_TextoAternativo01}",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "${this.aDTO_Imagen.aDTO_SeccionImagen[i].DTO_Imagen.s_TextoAternativo02}"
            }
          }`;
          if (i !== 3) {
            s_Json += ',';
          }
        }
        s_Json += `]
      } </script>`;
      this.s_JsonLD  = this.ats_DomSanitizer.bypassSecurityTrustHtml(s_Json);
    }
}
