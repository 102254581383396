import { Injectable, Injector, Inject, PLATFORM_ID } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { isPlatformServer, isPlatformBrowser, DOCUMENT } from '@angular/common';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { environment} from '../../../environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(
    private title: Title,
    private meta: Meta,
    private injector: Injector,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private doc,
    private router: Router,
    ) { }

  /**
   * **cf_SEO**
   * Modifica los datos meta tag para el SEO
   * @param s_Url Url para mostrar al compartir en redes sociales
   * @param s_Titulo Titulo de la pagina para mostrar en la seccion a compartir
   * @param s_Imagen Url de la imagen que se compartira en las redes sociales
   * @param s_Descripcion Descripcion de la página que se mostrara en las busquedas de google y al compartir
   */
  cf_SEO(s_Url: string, s_Titulo: string, s_Imagen: string, s_Descripcion: string ) {
    this.title.setTitle(s_Titulo);
    this.meta.updateTag({ property: 'og:url', content: s_Url });
    this.meta.updateTag({ property: 'og:title', content: s_Titulo});
    this.meta.updateTag({ property: 'og:image', content: s_Imagen });
    this.meta.updateTag({ property: 'og:type', content: 'website'});
    this.meta.updateTag({ property: 'og:description', content: s_Descripcion });
    this.meta.updateTag({ content: s_Descripcion, name: 'description' });
    this.meta.updateTag({ property: 'fb:app_id', content: environment.s_FbAppId });
  }

  public cf_GetUrl(): string {
    const s_UrlActual = this.router.url;
    let url = '';
    if (isPlatformServer(this.platformId)) {
        const request = this.injector.get(REQUEST);
        url = 'https://' + request.get('host');
        const link = this.doc.getElementById('urlcanonical');
        link.setAttribute('rel', 'canonical');
        // this.doc.head.appendChild(link);
        link.setAttribute('href', url + s_UrlActual);
    } else if (isPlatformBrowser(this.platformId)) {
        const location = window.location;
        url = 'https://' + location.host;
        const link = this.doc.getElementById('urlcanonical');
        link.setAttribute('rel', 'canonical');
        // this.doc.head.appendChild(link);
        link.setAttribute('href', url + s_UrlActual);
    }
    return url;
  }
}
