import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { HttpClientService } from '../Common/Servicios/http-client.service';
import { SitioWebDTO } from '../Common/Models/SitioWebDTO';
import { environment } from '../../environments/environment';
import { InmuebleNivelDTO } from '../Common/Models/InmuebleNivelDTO';
import { isPlatformBrowser } from '@angular/common';
import { SeoService } from '../Generales/servicios/seo.service';

@Component({
  selector: 'app-mapa',
  templateUrl: './mapa.component.html'
})
export class MapaComponent implements OnInit {
  href: string;
  aDTO_SitioWeb: SitioWebDTO = null;
  aDTO_Niveles: InmuebleNivelDTO[] = [];
  is_PlanogramaRuta: string = '';
  i_WindowWidth = 0;
  s_ImagenPrincipal = '';
  s_Estilo: string = '';
  s_Basehref: string;
  s_UrlActual: string;
  s_Url: string;
  s_TextoMapa = '';

  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    private ats_HttpCms: HttpClientService,
    private ats_SEO: SeoService,
    private router: Router) {
      if (isPlatformBrowser(this.platformId)) {
        this.aDTO_SitioWeb = JSON.parse(sessionStorage.getItem('sitio'));
        this.i_WindowWidth = window.screen.width;
      }
   }

  ngOnInit() {
    this.s_Basehref = this.ats_SEO.cf_GetUrl();
    this.s_UrlActual = this.router.url;
    this.s_Url = this.s_Basehref + this.s_UrlActual;
    this.cf_GetInmuebleNivel();
    this.s_ImagenPrincipal = environment.s_MapaBanner;
    this.s_Estilo = `url('${environment.s_MapaBanner}')`;
    this.is_PlanogramaRuta = environment.s_PlanogramaRuta;
    this.s_TextoMapa = environment.s_MapaTexto;

    this.ats_SEO.cf_SEO(this.s_Basehref + this.router.url, `Mapa de ${environment.s_NombreSitio}` ,
      environment.s_MapaBanner, 'Mapa de centrocomercial, ubicacion de los locales en la plaza');
  }

  cf_GetInmuebleNivel() {
    const aParameters = {
      i_IdPlaza: environment.i_IdPlaza
    };
    this.ats_HttpCms.cf_GetListByAction('InmuebleNivelES', 11, aParameters).subscribe(Item => {
      if (Item !== null && Item.length > 0) {
        this.aDTO_Niveles = Item;
      }
    });
  }

}
