import { isPlatformBrowser } from '@angular/common';
import { Component, Input, OnInit, OnChanges,  Inject, PLATFORM_ID } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { environment} from '../../../environments/environment';

@Component({
  selector: 'app-fb-button',
  templateUrl: './fb-button.component.html',
  styleUrls: ['./fb-button.component.css']
})
export class FbButtonComponent implements OnInit, OnChanges {
  s_UrlFacebookFrame: any;
  @Input() s_Url: string = '';
  @Input() s_Tipo: string = '';
  @Input() s_Mensaje: string = '';
  @Input() s_Tamanio: string = '';
  @Input() s_Estilo: string = '';
  @Input() s_Tags: string = '';
  @Input() s_Lat: string = '';
  @Input() s_Lng: string = '';
  constructor(
    private ats_DomSanitizer: DomSanitizer,
    @Inject(PLATFORM_ID) private platformId: string) { }

  ngOnChanges(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.s_UrlFacebookFrame  = '';
      // tslint:disable-next-line: max-line-length
      const s_FrameHtml = `<iframe title="Boton facebook" src="https://www.facebook.com/plugins/share_button.php?href=${this.s_Url}&layout=button&size=large&width=103&height=28&appId=${environment.s_FbAppId}" width="103" height="28" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media"></iframe>`;
      this.s_UrlFacebookFrame = this.ats_DomSanitizer.bypassSecurityTrustHtml(s_FrameHtml);
    }
  }

  ngOnInit() {
  }


}
