﻿/*
 * Copyright © 2012-2021 Grupo Frisa and its subsidiaries.
 * Version Generador: 2.3.4.0 - Angular TypeScript
 */

import { BaseDTO } from '../BaseClasses/Pattern/BaseDTO';
import { InmuebleDTO } from "./InmuebleDTO";

import { PlazaContratoDTO } from './PlazaContratoDTO';

/**
 * **InmuebleNivelDTO**

 * Llave Primaria: 
 * - DTO_Inmueble.i_IdRs
 * - DTO_Inmueble.i_IdInmueble
 * - i_IdNivel

 * Propiedades Fk (DTO): 
 * - DTO_Inmueble.i_IdRs
 * - DTO_Inmueble.i_IdInmueble

 * Dependientes (DTO): 
 * - aDTO_PlazaContrato
 */
export class InmuebleNivelDTO extends BaseDTO {
    public DTO_Inmueble: InmuebleDTO;
    public i_IdNivel: number;
    public s_Nombre: string;
    public s_NombreReal: string;
    public s_MostrarContratos: string;
    public i_IdPlanograma: number;
    public s_PlanogramaNombre: string;
    public s_PlanogramaMostrar: string;
    public s_PlanogramaRuta: string;
    public dt_PlanogramaFecha: Date;
    public i_PlanogramaBrochureX: number;
    public i_PlanogramaBrochureY: number;
    public s_Reservado01: string;
    public i_IdModxNivel: number;
    public i_Ordenacion: number;

    /*
     * Entidades que dependen de InmuebleNivelDTO
     */
    public aDTO_PlazaContrato: PlazaContratoDTO[];

    /**
     * **constructor**
     *
     * Crea los DTOs (FKs) de la instancia e inicializa atributos
     * @param ai_IdRs number
     * @param ai_IdInmueble number
     * @param ai_IdNivel number
     * @param ab_SetupDTO Boolean
     * @returns void
     */
    constructor(ai_IdRs: number = 0, ai_IdInmueble: number = 0, ai_IdNivel: number = 0, ab_SetupDTO: Boolean = true) {
        super();

        this.cf_CreateLevelDTO(ab_SetupDTO);

        if (ab_SetupDTO) { this.cf_SetupDTO(); }

        this.DTO_Inmueble.i_IdRs = ai_IdRs;
        this.DTO_Inmueble.i_IdInmueble = ai_IdInmueble;
        this.i_IdNivel = ai_IdNivel;
    }

    /**
     * **cf_CreateLevelDTO**
     *
     * Esta funcion no debe de ser SobreEscrita.
     *
     * Crea un DTO hasta el nivel solicitado (llaves foraneas).
     *
     * Inicializa sus propiedades si es que fue requerido.
     * @param ab_SetupDTO Indica si se inicializaran las variables del DTO
     * @returns void
     */
    public cf_CreateLevelDTO(ab_SetupDTO: Boolean): void {
        this.DTO_Inmueble = new InmuebleDTO(null, null, false);
    }

    /**
     * **cf_SetupDTO**
     *
     * 	Inicializa todos los atributos del DTO, excepto la PK
     * @returns void
     */
    public cf_SetupDTO(): void {
        this.s_Nombre = '';
        this.s_NombreReal = '';
        this.s_MostrarContratos = '';
        this.i_IdPlanograma = 0;
        this.s_PlanogramaNombre = '';
        this.s_PlanogramaMostrar = '';
        this.s_PlanogramaRuta = '';
        this.dt_PlanogramaFecha = new Date(1900, 1, 1);
        this.i_PlanogramaBrochureX = 0;
        this.i_PlanogramaBrochureY = 0;
        this.s_Reservado01 = '';
        this.i_IdModxNivel = 0;
        this.i_Ordenacion = 0;
    }

    /**
     * **cf_Clone**
     *
     * Esta funcion no deberia de ser SobreEscrita.
     *
     * 	Clona el DTO (mismos atributos y estado), regresa un DTO de tipo InmuebleNivelDTO.
     * @returns InmuebleNivelDTO
     */
    public cf_Clone(): BaseDTO {
        let lDT_New: InmuebleNivelDTO;

        lDT_New = new InmuebleNivelDTO(null, null, null, false);
        lDT_New.e_CloneAttributes(this);

        return lDT_New;
    }

    /**
     * **cf_SamePK**
     *
     * Esta funcion no deberia de ser SobreEscrita.
     *
     * 	Revisa si la llave primaria de este DTO es igual a la del argumento (InmuebleNivelDTO).
     * @param aDT_Entity El DTO contra el que se va a comparar la PK
     * @returns Boolean
     */
    public cf_SamePK(aDT_Entity: BaseDTO): Boolean {
        let lDT_Entity: InmuebleNivelDTO;

        lDT_Entity = aDT_Entity as InmuebleNivelDTO;

        if (this.DTO_Inmueble === null || lDT_Entity.DTO_Inmueble === null) { return false; }
        if (!this.DTO_Inmueble.cf_SamePK(lDT_Entity.DTO_Inmueble)) { return false; }

        if (this.i_IdNivel === null || lDT_Entity.i_IdNivel === null) return false;
        if (this.i_IdNivel !== lDT_Entity.i_IdNivel) return false;

        return true;
    }

    /**
     * **e_CloneAttributes**
     *
     * Este evento podria ser SobreCargado (en caso de que se declaren variables en el DTO).
     *
     * 	Copia los valores de todos los atributos del DTO Original a este que es el Clon
     * @param aDTO_Original InmuebleNivelDTO
     * @returns Nada
     */
    public e_CloneAttributes(aDTO_Original: BaseDTO): void {
        let lDTO_Original: InmuebleNivelDTO;

        super.e_CloneAttributes(aDTO_Original);

        lDTO_Original = aDTO_Original as InmuebleNivelDTO;

        if (lDTO_Original.DTO_Inmueble !== null) { this.DTO_Inmueble = lDTO_Original.DTO_Inmueble.cf_Clone() as InmuebleDTO; }
        this.i_IdNivel = lDTO_Original.i_IdNivel;
        this.s_Nombre = lDTO_Original.s_Nombre;
        this.s_NombreReal = lDTO_Original.s_NombreReal;
        this.s_MostrarContratos = lDTO_Original.s_MostrarContratos;
        this.i_IdPlanograma = lDTO_Original.i_IdPlanograma;
        this.s_PlanogramaNombre = lDTO_Original.s_PlanogramaNombre;
        this.s_PlanogramaMostrar = lDTO_Original.s_PlanogramaMostrar;
        this.s_PlanogramaRuta = lDTO_Original.s_PlanogramaRuta;
        this.dt_PlanogramaFecha = lDTO_Original.dt_PlanogramaFecha;
        this.i_PlanogramaBrochureX = lDTO_Original.i_PlanogramaBrochureX;
        this.i_PlanogramaBrochureY = lDTO_Original.i_PlanogramaBrochureY;
        this.s_Reservado01 = lDTO_Original.s_Reservado01;
        this.i_IdModxNivel = lDTO_Original.i_IdModxNivel;
        this.i_Ordenacion = lDTO_Original.i_Ordenacion;
    }
}
