import { MultimediaComponent } from "src/app/multimedia/multimedia.component";
import { BuscarTiendaComponent } from "../app/buscar-tienda/buscar-tienda.component";
import { ContactoComponent } from "../app/contacto/contacto.component";
import { DetalleTiendaBasicoComponent } from "../app/detalle-tienda-basico/detalle-tienda-basico.component";
import { DetalleTiendaCompletoComponent } from "../app/detalle-tienda-completo/detalle-tienda-completo.component";
import { ErrorComponent } from "../app/error/error.component";
import { EventosComponent } from "../app/eventos/eventos.component";
import { GaleriaComponent } from "../app/galeria/galeria.component";
import { HomeComponent } from "../app/home/home.component";
import { InformacionComponent } from "../app/informacion/informacion.component";
import { MapaComponent } from "../app/mapa/mapa.component";
import { NovedadesComponent } from "../app/novedades/novedades.component";
import { PreguntasComponent } from "../app/preguntas/preguntas.component";
import { PromocionesComponent } from "../app/promociones/promociones.component";
import { RentaEspacioComponent } from "../app/renta-espacio/renta-espacio.component";
import { RentaIntermediaComponent } from "../app/renta-intermedia/renta-intermedia.component";
import { ServiciosComponent } from "../app/servicios/servicios.component";
import { TiendasComponent } from "../app/tiendas/tiendas.component";


export const RoutesSite= {
  //MUNDO E Y MAGNOCENTRO 
    a_routes: [
        {
          path: 'home',
          component: HomeComponent,
        },
        {
          path: 'novedades',
          component: NovedadesComponent,
        },
        {
          path: 'tiendas',
          component: TiendasComponent,
        },
        {
          path: 'tienda/:s_TiendaNombre/:i_IdPlantilla',
          component: DetalleTiendaBasicoComponent,
        },
        {
          path: 'tiendaC/:s_TiendaNombre/:i_IdPlantilla',
          component: DetalleTiendaCompletoComponent,
        },
        {
          path: 'todas-las-tiendas',
          component: BuscarTiendaComponent,
        },
        {
          path: 'todas-las-tiendas/:i_IdPlantilla',
          component: BuscarTiendaComponent,
        },
        {
          path: 'todas-las-tiendas/:i_IdPlantilla/:s_Busqueda',
          component: BuscarTiendaComponent,
        },
        {
          path: 'entretenimiento',
          component: TiendasComponent,
        },
        {
          path: 'restaurantes',
          component: TiendasComponent,
        },
        {
          path: 'eventos',
          component: EventosComponent,
        },
        {
          path: 'eventos/:i_IdEvento',
          component: EventosComponent,
        },
        {
          path: 'mapa',
          component: MapaComponent,
        },
        {
          path: 'galeria',
          component: GaleriaComponent,
        },
        {
          path: 'promociones',
          component: PromocionesComponent,
        },
        {
          path: 'promociones/:i_IdEvento',
          component: PromocionesComponent,
        },
        {
          path: 'renta-de-espacios',
          component: RentaIntermediaComponent,
        },
        {
          path: 'renta-de-espacios-publicitarios',
          component: RentaEspacioComponent,
        },
        {
          path: 'renta-de-locales',
          component: RentaEspacioComponent,
        },
        {
          path: 'servicios',
          component: ServiciosComponent,
        },
        {
          path: 'preguntas-frecuentes',
          component: PreguntasComponent,
        },
        {
          path: 'contacto',
          component: ContactoComponent,
        },
        {
          path: 'informacion',
          component: InformacionComponent,
        },
        {
          path: '',
          redirectTo: 'home',
          pathMatch: 'full'
        },
        {
          path: 'error',
          component: ErrorComponent,
        },
        {
          path: '**',
          component: ErrorComponent,
        }]

          //**********PARQUE*********+
    // a_routes: [
    //   {
    //     path: 'home',
    //     component: HomeComponent,
    //   },
    //   {
    //     path: 'novedades',
    //     component: NovedadesComponent,
    //   },
    //   {
    //     path: 'parques',
    //     component: TiendasComponent,
    //   },
    //   {
    //     path: 'parque/:s_TiendaNombre/:i_IdPlantilla',
    //     component: DetalleTiendaBasicoComponent,
    //   },
    //   {
    //     path: 'parqueC/:s_TiendaNombre/:i_IdPlantilla',
    //     component: DetalleTiendaCompletoComponent,
    //   },
    //   {
    //     path: 'todos-los-parques',
    //     component: BuscarTiendaComponent,
    //   },
    //   {
    //     path: 'todos-los-parques/:i_IdPlantilla',
    //     component: BuscarTiendaComponent,
    //   },
    //   {
    //     path: 'todos-los-parques/:i_IdPlantilla/:s_Busqueda',
    //     component: BuscarTiendaComponent,
    //   },
    //   {
    //     path: 'disponibilidad',
    //     component: EventosComponent,
    //   },
    //   {
    //     path: 'noticias',
    //     component: PromocionesComponent,
    //   },
    //   {
    //     path: 'noticias/:i_IdEvento',
    //     component: PromocionesComponent,
    //   },
    //   {
    //     path: 'preguntas-frecuentes',
    //     component: PreguntasComponent,
    //   },
    //   {
    //     path: 'nosotros',
    //     component: ContactoComponent,
    //   },
    //   {
    //     path: 'contacto',
    //     component: ContactoComponent,
    //   },
    //   {
    //     path: 'mapa',
    //     component: MultimediaComponent,
    //   },
    //   {
    //     path: '',
    //     redirectTo: 'home',
    //     pathMatch: 'full'
    //   },
    //   {
    //     path: 'error',
    //     component: ErrorComponent,
    //   },
    //   {
    //     path: '**',
    //     component: ErrorComponent,
    //   }]
}