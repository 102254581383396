import { Component, OnInit, PLATFORM_ID, Inject, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClientService } from '../Common/Servicios/http-client.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SitioWebDTO } from '../Common/Models/SitioWebDTO';
import { PlantillaDTO } from '../Common/Models/PlantillaDTO';
import { SeccionDTO } from '../Common/Models/SeccionDTO';
import { SeoService } from '../Generales/servicios/seo.service';
import { isPlatformBrowser } from '@angular/common';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { environment } from '../../environments/environment';
import { PlantillaSeccionDTO } from '../Common/Models/PlantillaSeccionDTO';
@Component({
  selector: 'app-renta-espacio',
  templateUrl: './renta-espacio.component.html',
  styleUrls: ['./renta-espacio.component.css']
})
export class RentaEspacioComponent implements OnInit, OnDestroy {
  ifg_Contacto: FormGroup;
  s_UrlActual: string = '';
  aDTO_SitioWeb: SitioWebDTO = null;
  aDTO_PlantillaRenta: PlantillaDTO = null;
  aDTOEspacios: SeccionDTO[] = [];
  s_Basehref: string = '';
  siteKey = '';
  s_TokenCaptcha: string = '';
  ib_deshabilita: boolean = false;
  i_WindowWidth = 0;
  b_MostrarSucces = false;
  b_MostrarWarn = false;
  aDTO_Banner: SeccionDTO = new SeccionDTO();
  aDTO_Imgform: SeccionDTO = new SeccionDTO();
  aDTO_SeccionesRenta: SeccionDTO = new SeccionDTO();
  s_Url: string;
  s_MensajeError: string;
  // tslint:disable-next-line: max-line-length
  reg_Telefono = /^(\(\+?\d{2,3}\)[\*|\s|\-|\.]?(([\d][\*|\s|\-|\.]?){6})(([\d][\s|\-|\.]?){2})?|(\+?[\d][\s|\-|\.]?){8}(([\d][\s|\-|\.]?){2}(([\d][\s|\-|\.]?){2})?)?)$/;
  e_DatosPlaza: any;
  constructor(private router: Router,
    private ats_HttpCms: HttpClientService,
    private ats_SEO: SeoService,
    private ats_Router: Router,
    @Inject(PLATFORM_ID) private platformId: string,
    private reCaptchaV3Service: ReCaptchaV3Service) {
    if (isPlatformBrowser(this.platformId)) {
      this.aDTO_SitioWeb = JSON.parse(sessionStorage.getItem('sitio'));
      this.i_WindowWidth = window.screen.width;
    }
  }

  ngOnInit() {
    this.siteKey = environment.s_SiteKeyCaptcha;
    this.e_DatosPlaza = environment.e_DatosPlaza;
    this.s_MensajeError = '';
    this.cf_Formulario();
    this.cf_GenerarTokenCaptcha();
    this.s_Basehref = this.ats_SEO.cf_GetUrl();
    this.s_UrlActual = this.router.url;
    this.s_Url = this.s_Basehref + this.s_UrlActual;
    if (this.aDTO_SitioWeb !== null) {
      switch (this.s_UrlActual) {
        case '/renta-de-espacios-publicitarios':
          this.aDTO_PlantillaRenta = new PlantillaDTO(this.aDTO_SitioWeb.i_IdPlantillaRentaEspacios);
          break;
        case '/renta-de-locales':
          this.aDTO_PlantillaRenta = new PlantillaDTO(environment.i_IdPlantillaRentaLocales);
          break;
        default:
          break;
      }
      this.cf_ReadPlantilla();
    } else {
      this.aDTO_SitioWeb = new SitioWebDTO(environment.i_IdSitioweb);
      this.cf_Read();
    }
  }

  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId)) {
      const capctha = document.getElementsByClassName('grecaptcha-badge');
      const tabToDisplay = capctha.item(0) as HTMLElement;
      tabToDisplay.classList.add('d-none');

    }
  }

  /**
     * **cf_GenerarTokenCaptcha**
     * Genera un token del servicio de recaptcha de google
     */
  cf_GenerarTokenCaptcha() {
    if (isPlatformBrowser(this.platformId)) {
      this.reCaptchaV3Service.execute(
        this.siteKey,
        'login',
        (token) => {
          this.s_TokenCaptcha = token;
            const capctha = document.getElementsByClassName('grecaptcha-badge');
            const tabToDisplay = capctha.item(0) as HTMLElement;
            tabToDisplay.classList.remove('d-none');
        },
        {
          useGlobalDomain: false,
        }
      );
    }
  }

  cf_ReadPlantilla() {
    const aParameters = {
      i_IdSitioWeb: environment.i_IdSitioweb
    };
    this.ats_HttpCms.cf_ReadPC('PlantillaES', this.aDTO_PlantillaRenta, 11, aParameters).subscribe(Item => {
      if (Item !== null) {
        this.aDTO_PlantillaRenta = Item as PlantillaDTO;
        this.aDTOEspacios = [];
        for (let i = 0; i < this.aDTO_PlantillaRenta.aDTO_PlantillaSeccion.length; i++) {
          switch (this.aDTO_PlantillaRenta.aDTO_PlantillaSeccion[i].DTO_Seccion.i_IdUbicacion) {
            case 1:
              this.aDTO_Banner = this.aDTO_PlantillaRenta.aDTO_PlantillaSeccion[i].DTO_Seccion;
              break;
            case 2:
                this.aDTO_Imgform = this.aDTO_PlantillaRenta.aDTO_PlantillaSeccion[i].DTO_Seccion;
              break;
            case 3:
              this.aDTO_SeccionesRenta = this.aDTO_PlantillaRenta.aDTO_PlantillaSeccion[i].DTO_Seccion;
              break;
            default:
              break;
          }
        }
        const s_ImagenRuta = `${this.s_Basehref}${this.aDTO_PlantillaRenta.s_CeoReservado3}`;
        this.ats_SEO.cf_SEO(this.s_Basehref + this.ats_Router.url, this.aDTO_PlantillaRenta.s_CeoReservado1,
          s_ImagenRuta, this.aDTO_PlantillaRenta.s_CeoReservado2);
      }
    });
  }


  cf_Formulario() {
    this.ifg_Contacto = new FormGroup({
      s_Nombre: new FormControl({ value: '', disabled: false }, [Validators.required]),
      s_Email: new FormControl({ value: '', disabled: false }, [Validators.required, this.v_ValidacionEmail]),
      s_Telefono: new FormControl({ value: '', disabled: false }, [Validators.pattern(this.reg_Telefono)]),
      s_Empresa: new FormControl({ value: '', disabled: false }),
      s_Comentario: new FormControl({ value: '', disabled: false })
    });

  }

  v_ValidacionEmail(control: FormControl): { [s: string]: boolean } {
    if (/^[^@]+@[^@]+\.[a-zA-Z]{2,}$/.test(control.value)) {
      return null;
    } else if (control.value === null || control.value === '') {
      return null;
    } else {
      return {
        CorreoIncorrecto: true
      };
    }
  }

  cf_Enviar() {
    if (this.ifg_Contacto.valid) {
      this.ib_deshabilita = true;
      // if (this.ifg_Contacto.controls['b_terminos'].value) {
      let aDatos: {
        s_Nombre: string,
        s_Email: string,
        s_Telefono: string,
        s_Empresa: string,
        s_Comentario: string,
        TokenCaptcha: string,
        i_IdSitioWeb: number,
        i_IdTipoFormulario: number
      } = {
        s_Nombre: '',
        s_Email: '',
        s_Telefono: '',
        s_Empresa: '',
        s_Comentario: '',
        TokenCaptcha: this.s_TokenCaptcha,
        i_IdSitioWeb: environment.i_IdSitioweb,
        i_IdTipoFormulario: 0,
      };
      switch (this.s_UrlActual) {
        case '/renta-de-espacios-publicitarios':
          aDatos.i_IdTipoFormulario = 3;
          break;
        case '/renta-de-locales':
          aDatos.i_IdTipoFormulario = 2;
          break;
        default:
          break;
      }
      Object.assign(aDatos, this.ifg_Contacto.value);
      const s_aDatos = JSON.stringify(aDatos);
      this.ats_HttpCms.cf_SubmitForm(s_aDatos).subscribe(Item => {
        if (Item !== null) {
          this.ifg_Contacto.reset();
          this.ifg_Contacto.markAsPristine();
          this.ifg_Contacto.markAsUntouched();
          Object.keys(this.ifg_Contacto.controls).forEach(key => {
            this.ifg_Contacto.get(key).setErrors(null);
          });
          this.b_MostrarSucces = true;
          this.b_MostrarWarn = false;
          this.cf_GenerarTokenCaptcha();
        } else {
          this.b_MostrarWarn = true;
          this.s_MensajeError = 'Algo salió mal, favor de intentarlo nuevamente. ';
          this.cf_GenerarTokenCaptcha();
        }
        this.ib_deshabilita = false;
      });
    } else {
      this.b_MostrarWarn = true;
      this.s_MensajeError = 'Favor de llenar correctamente los datos.';
      this.cf_GenerarTokenCaptcha();
    }
  }

  cf_Read() {
    this.ats_HttpCms.cf_ReadPC('SitioWebES', this.aDTO_SitioWeb).subscribe(Item => {
      if (Item !== null) {
        this.aDTO_SitioWeb = Item as SitioWebDTO;
        if (isPlatformBrowser(this.platformId)) {
          sessionStorage.setItem('sitio', JSON.stringify(this.aDTO_SitioWeb));
        }
        this.aDTO_PlantillaRenta = new PlantillaDTO(this.aDTO_SitioWeb.i_IdPlantillaRentaEspacios);
        this.cf_ReadPlantilla();
      }
    });
  }

  cf_Scroolt() {
    if (isPlatformBrowser(this.platformId)) {
      document.querySelector('mat-sidenav-content').scrollTop = 0 + 400;
    }
  }

}
