﻿/*
 * Copyright © 2012-2021 Grupo Frisa and its subsidiaries.
 * Version Generador: 2.3.4.0 - Angular TypeScript
 */

import { BaseDTO } from '../BaseClasses/Pattern/BaseDTO';
import { PlazaDTO } from "./PlazaDTO";
import { InmuebleNivelDTO } from "./InmuebleNivelDTO";


/**
 * **PlazaContratoDTO**

 * Llave Primaria: 
 * - DTO_Plaza.i_IdPlaza
 * - i_IdContrato

 * Propiedades Fk (DTO): 
 * - DTO_Plaza.i_IdPlaza
 * - DTO_InmuebleNivel.DTO_Inmueble.i_IdRs
 * - DTO_InmuebleNivel.DTO_Inmueble.i_IdInmueble
 * - DTO_InmuebleNivel.i_IdNivel
 */
export class PlazaContratoDTO extends BaseDTO {
    public DTO_Plaza: PlazaDTO;
    public i_IdContrato: number;
    public i_IdTipoRegistro: number;
    public i_IdPlantilla: number;
    public s_ContratoCodigo: string;
    public s_ContratoNombreComercial: string;
    public s_ContratoSituacion: string;
    public s_ContratoAreasRentables: string;
    public s_ContratoCategoria: string;
    public s_ContratoGiro: string;
    public DTO_InmuebleNivel: InmuebleNivelDTO;
    public i_IdMarca: number;
    public s_Marca: string;
    public i_IdVisibilidadMarca: number;
    public i_OrdenacionMarca: number;
    public s_TarjetaMostrar: string;
    public s_TarjetaTitulo: string;
    public i_IdTarjetaSituacion: number;
    public s_TarjetaDescripcionSuperior: string;
    public s_TarjetaDescripcionInferior: string;
    public s_TarjetaFiltros: string;
    public i_TarjetaIdPoligono: number;
    public s_TarjetaCodigoPoligono: string;
    public s_TarjetaUrlAlias: string;
    public i_OrdenacionTarjeta: number;
    public s_PlanogramaMostrar: string;
    public s_Horario: string;
    public s_Telefono: string;
    public s_Contacto: string;
    public i_IdRedSocial01: number;
    public i_IdRedSocial02: number;
    public i_IdRedSocial03: number;
    public dc_Superficie: number;
    public dc_Frente: number;
    public dc_Fondo: number;
    public dc_Altura: number;
    public i_IdImagenLogo: number;

    /**
     * **constructor**
     *
     * Crea los DTOs (FKs) de la instancia e inicializa atributos
     * @param ai_IdPlaza number
     * @param ai_IdContrato number
     * @param ab_SetupDTO Boolean
     * @returns void
     */
    constructor(ai_IdPlaza: number = 0, ai_IdContrato: number = 0, ab_SetupDTO: Boolean = true) {
        super();

        this.cf_CreateLevelDTO(ab_SetupDTO);

        if (ab_SetupDTO) { this.cf_SetupDTO(); }

        this.DTO_Plaza.i_IdPlaza = ai_IdPlaza;
        this.i_IdContrato = ai_IdContrato;
    }

    /**
     * **cf_CreateLevelDTO**
     *
     * Esta funcion no debe de ser SobreEscrita.
     *
     * Crea un DTO hasta el nivel solicitado (llaves foraneas).
     *
     * Inicializa sus propiedades si es que fue requerido.
     * @param ab_SetupDTO Indica si se inicializaran las variables del DTO
     * @returns void
     */
    public cf_CreateLevelDTO(ab_SetupDTO: Boolean): void {
        this.DTO_Plaza = new PlazaDTO(null, false);

        if (ab_SetupDTO) {
            this.DTO_InmuebleNivel = new InmuebleNivelDTO(null, null, null, false);
        }
    }

    /**
     * **cf_SetupDTO**
     *
     * 	Inicializa todos los atributos del DTO, excepto la PK
     * @returns void
     */
    public cf_SetupDTO(): void {
        this.i_IdTipoRegistro = 0;
        this.i_IdPlantilla = 0;
        this.s_ContratoCodigo = '';
        this.s_ContratoNombreComercial = '';
        this.s_ContratoSituacion = '';
        this.s_ContratoAreasRentables = '';
        this.s_ContratoCategoria = '';
        this.s_ContratoGiro = '';
        this.i_IdMarca = 0;
        this.s_Marca = '';
        this.i_IdVisibilidadMarca = 0;
        this.i_OrdenacionMarca = 0;
        this.s_TarjetaMostrar = '';
        this.s_TarjetaTitulo = '';
        this.i_IdTarjetaSituacion = 0;
        this.s_TarjetaDescripcionSuperior = '';
        this.s_TarjetaDescripcionInferior = '';
        this.s_TarjetaFiltros = '';
        this.i_TarjetaIdPoligono = 0;
        this.s_TarjetaCodigoPoligono = '';
        this.s_TarjetaUrlAlias = '';
        this.i_OrdenacionTarjeta = 0;
        this.s_PlanogramaMostrar = '';
        this.s_Horario = '';
        this.s_Telefono = '';
        this.s_Contacto = '';
        this.i_IdRedSocial01 = 0;
        this.i_IdRedSocial02 = 0;
        this.i_IdRedSocial03 = 0;
        this.dc_Superficie = 0;
        this.dc_Frente = 0;
        this.dc_Fondo = 0;
        this.dc_Altura = 0;
        this.i_IdImagenLogo = 0;
    }

    /**
     * **cf_Clone**
     *
     * Esta funcion no deberia de ser SobreEscrita.
     *
     * 	Clona el DTO (mismos atributos y estado), regresa un DTO de tipo PlazaContratoDTO.
     * @returns PlazaContratoDTO
     */
    public cf_Clone(): BaseDTO {
        let lDT_New: PlazaContratoDTO;

        lDT_New = new PlazaContratoDTO(null, null, false);
        lDT_New.e_CloneAttributes(this);

        return lDT_New;
    }

    /**
     * **cf_SamePK**
     *
     * Esta funcion no deberia de ser SobreEscrita.
     *
     * 	Revisa si la llave primaria de este DTO es igual a la del argumento (PlazaContratoDTO).
     * @param aDT_Entity El DTO contra el que se va a comparar la PK
     * @returns Boolean
     */
    public cf_SamePK(aDT_Entity: BaseDTO): Boolean {
        let lDT_Entity: PlazaContratoDTO;

        lDT_Entity = aDT_Entity as PlazaContratoDTO;

        if (this.DTO_Plaza === null || lDT_Entity.DTO_Plaza === null) { return false; }
        if (!this.DTO_Plaza.cf_SamePK(lDT_Entity.DTO_Plaza)) { return false; }

        if (this.i_IdContrato === null || lDT_Entity.i_IdContrato === null) return false;
        if (this.i_IdContrato !== lDT_Entity.i_IdContrato) return false;

        return true;
    }

    /**
     * **e_CloneAttributes**
     *
     * Este evento podria ser SobreCargado (en caso de que se declaren variables en el DTO).
     *
     * 	Copia los valores de todos los atributos del DTO Original a este que es el Clon
     * @param aDTO_Original PlazaContratoDTO
     * @returns Nada
     */
    public e_CloneAttributes(aDTO_Original: BaseDTO): void {
        let lDTO_Original: PlazaContratoDTO;

        super.e_CloneAttributes(aDTO_Original);

        lDTO_Original = aDTO_Original as PlazaContratoDTO;

        if (lDTO_Original.DTO_Plaza !== null) { this.DTO_Plaza = lDTO_Original.DTO_Plaza.cf_Clone() as PlazaDTO; }
        this.i_IdContrato = lDTO_Original.i_IdContrato;
        this.i_IdTipoRegistro = lDTO_Original.i_IdTipoRegistro;
        this.i_IdPlantilla = lDTO_Original.i_IdPlantilla;
        this.s_ContratoCodigo = lDTO_Original.s_ContratoCodigo;
        this.s_ContratoNombreComercial = lDTO_Original.s_ContratoNombreComercial;
        this.s_ContratoSituacion = lDTO_Original.s_ContratoSituacion;
        this.s_ContratoAreasRentables = lDTO_Original.s_ContratoAreasRentables;
        this.s_ContratoCategoria = lDTO_Original.s_ContratoCategoria;
        this.s_ContratoGiro = lDTO_Original.s_ContratoGiro;
        if (lDTO_Original.DTO_InmuebleNivel !== null) { this.DTO_InmuebleNivel = lDTO_Original.DTO_InmuebleNivel.cf_Clone() as InmuebleNivelDTO; }
        this.i_IdMarca = lDTO_Original.i_IdMarca;
        this.s_Marca = lDTO_Original.s_Marca;
        this.i_IdVisibilidadMarca = lDTO_Original.i_IdVisibilidadMarca;
        this.i_OrdenacionMarca = lDTO_Original.i_OrdenacionMarca;
        this.s_TarjetaMostrar = lDTO_Original.s_TarjetaMostrar;
        this.s_TarjetaTitulo = lDTO_Original.s_TarjetaTitulo;
        this.i_IdTarjetaSituacion = lDTO_Original.i_IdTarjetaSituacion;
        this.s_TarjetaDescripcionSuperior = lDTO_Original.s_TarjetaDescripcionSuperior;
        this.s_TarjetaDescripcionInferior = lDTO_Original.s_TarjetaDescripcionInferior;
        this.s_TarjetaFiltros = lDTO_Original.s_TarjetaFiltros;
        this.i_TarjetaIdPoligono = lDTO_Original.i_TarjetaIdPoligono;
        this.s_TarjetaCodigoPoligono = lDTO_Original.s_TarjetaCodigoPoligono;
        this.s_TarjetaUrlAlias = lDTO_Original.s_TarjetaUrlAlias;
        this.i_OrdenacionTarjeta = lDTO_Original.i_OrdenacionTarjeta;
        this.s_PlanogramaMostrar = lDTO_Original.s_PlanogramaMostrar;
        this.s_Horario = lDTO_Original.s_Horario;
        this.s_Telefono = lDTO_Original.s_Telefono;
        this.s_Contacto = lDTO_Original.s_Contacto;
        this.i_IdRedSocial01 = lDTO_Original.i_IdRedSocial01;
        this.i_IdRedSocial02 = lDTO_Original.i_IdRedSocial02;
        this.i_IdRedSocial03 = lDTO_Original.i_IdRedSocial03;
        this.dc_Superficie = lDTO_Original.dc_Superficie;
        this.dc_Frente = lDTO_Original.dc_Frente;
        this.dc_Fondo = lDTO_Original.dc_Fondo;
        this.dc_Altura = lDTO_Original.dc_Altura;
        this.i_IdImagenLogo = lDTO_Original.i_IdImagenLogo;
    }
}
