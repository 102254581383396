export class ErrorEstandarDTO {
    s_Codigo: string;
    s_Descripcion: string;
    s_Tipo: string;
    s_Severidad: string;

    constructor(as_Codigo?: string, as_Descripcion?: string, as_Tipo?: string, as_Severidad?: string) {
        this.s_Codigo = as_Codigo;
        this.s_Descripcion = as_Descripcion;
        this.s_Tipo = as_Tipo;
        this.s_Severidad = as_Severidad;

        if (this.s_Codigo == null) this.s_Codigo = '';
        if (this.s_Descripcion == null) this.s_Descripcion = '';
        if (this.s_Tipo == null) this.s_Tipo = '';
        if (this.s_Severidad == null) this.s_Severidad = '';
    }
}
