import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClientService } from '../Common/Servicios/http-client.service';
import { PlantillaDTO } from '../Common/Models/PlantillaDTO';
import { SeccionDTO } from '../Common/Models/SeccionDTO';
import { SitioWebDTO } from '../Common/Models/SitioWebDTO';
import { SeccionImagenDTO } from '../Common/Models/SeccionImagenDTO';
import { SeoService } from '../Generales/servicios/seo.service';
import { isPlatformBrowser } from '@angular/common';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-servicios',
  templateUrl: './servicios.component.html',
  styleUrls: ['./servicios.component.css']
})
export class ServiciosComponent implements OnInit {
  href: string;
  aDTO_PlantillaServicios: PlantillaDTO = new PlantillaDTO(environment.i_IdPlantillaServicios);
  aDTO_Principal: SeccionDTO = null;
  aDTO_Servicios: SeccionImagenDTO[] = null;
  aDTO_SitioWeb: SitioWebDTO = null;
  s_Basehref: string = '';
  s_UrlActual: string = '';
  i_WindowWidth = 0;

  constructor(private router: Router,
    private ats_HttpCms: HttpClientService,
    private ats_SEO: SeoService,
    @Inject(PLATFORM_ID) private platformId: string) { }

  ngOnInit() {
    this.s_Basehref = this.ats_SEO.cf_GetUrl();
    this.s_UrlActual = this.router.url;
    this.cf_ReadPlantilla();
    if (isPlatformBrowser(this.platformId)) {
      this.aDTO_SitioWeb = JSON.parse(sessionStorage.getItem('sitio'));
      this.i_WindowWidth = window.screen.width;
    }
  }

  cf_ReadPlantilla() {
    const aParameters = {
      i_IdSitioWeb: environment.i_IdSitioweb
    };
    this.ats_HttpCms.cf_ReadPC('PlantillaES', this.aDTO_PlantillaServicios, 11, aParameters).subscribe(Item => {
      if (Item !== null) {
        this.aDTO_PlantillaServicios = Item as PlantillaDTO;
        for (let i = 0; i < this.aDTO_PlantillaServicios.aDTO_PlantillaSeccion.length; i++) {
          switch (this.aDTO_PlantillaServicios.aDTO_PlantillaSeccion[i].DTO_Seccion.i_IdUbicacion) {
            case 1:
              this.aDTO_Principal = this.aDTO_PlantillaServicios.aDTO_PlantillaSeccion[i].DTO_Seccion;
              break;
            case 2:
              this.aDTO_Servicios = this.aDTO_PlantillaServicios.aDTO_PlantillaSeccion[i].DTO_Seccion.aDTO_SeccionImagen;
              break;
            default:
              break;
          }
        }
        const s_ImagenRuta = `${this.s_Basehref}${this.aDTO_PlantillaServicios.s_CeoReservado3}`;
        this.ats_SEO.cf_SEO(this.s_Basehref + this.router.url,
          this.aDTO_PlantillaServicios.s_CeoReservado1, s_ImagenRuta, this.aDTO_PlantillaServicios.s_CeoReservado2);
      }
    });

  }

}
