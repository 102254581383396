import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { HttpClientService } from '../Common/Servicios/http-client.service';
import { PlantillaDTO } from '../Common/Models/PlantillaDTO';
import { SeccionDTO } from '../Common/Models/SeccionDTO';
import { Router } from '@angular/router';
import { SitioWebDTO } from '../Common/Models/SitioWebDTO';
import { isPlatformBrowser } from '@angular/common';
import { SeoService } from '../Generales/servicios/seo.service';
import { environment} from '../../environments/environment';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {
  aDTO_Home: PlantillaDTO = new PlantillaDTO();
  aDTO_Carrousel: SeccionDTO = new SeccionDTO(0);
  aDTO_TiendaDoble: SeccionDTO = null;
  aDTO_TiendaIndividual: SeccionDTO = null;
  aDTO_ConocenosImagen: SeccionDTO = null;
  aDTO_ConocenosTexto: SeccionDTO = null;
  aDTO_DobleSeccion: SeccionDTO = null;
  aDTO_TripleSeccion: SeccionDTO = null;
  s_Basehref: string;
  aDTO_SitioWeb: SitioWebDTO = null;

  constructor(
    private ats_HttpCms: HttpClientService,
    @Inject(PLATFORM_ID) private platformId: string,
    private ats_SEO: SeoService,
    private ats_Router: Router) {
      if (isPlatformBrowser(this.platformId)) {
      this.aDTO_SitioWeb = JSON.parse(sessionStorage.getItem('sitio'));
      }
    }

  ngOnInit() {
    this.s_Basehref = this.ats_SEO.cf_GetUrl();
    if (this.aDTO_SitioWeb !== null) {
      this.aDTO_Home = new PlantillaDTO(this.aDTO_SitioWeb.i_IdPlantillaHome);
      this.cf_CargaPagina();
    } else {
      this.aDTO_SitioWeb = new SitioWebDTO(environment.i_IdSitioweb);
      this.cf_Read();
    }
  }

  cf_CargaPagina() {
    const aParameters = {
      i_IdSitioWeb: environment.i_IdSitioweb
    };
    this.ats_HttpCms.cf_ReadPC('PlantillaES', this.aDTO_Home, 11, aParameters).subscribe(Item => {
      if (Item !== null) {
        this.aDTO_Home = Item as PlantillaDTO;
        for (let i = 0; i < this.aDTO_Home.aDTO_PlantillaSeccion.length; i++) {
          switch (this.aDTO_Home.aDTO_PlantillaSeccion[i].DTO_Seccion.i_IdUbicacion) {
            case 1:
              this.aDTO_Carrousel = this.aDTO_Home.aDTO_PlantillaSeccion[i].DTO_Seccion;
              break;
            case 2:
              this.aDTO_TiendaDoble = this.aDTO_Home.aDTO_PlantillaSeccion[i].DTO_Seccion;
              break;
            case 3:
              this.aDTO_TiendaIndividual = this.aDTO_Home.aDTO_PlantillaSeccion[i].DTO_Seccion;
              break;
            case 4:
              this.aDTO_ConocenosImagen = this.aDTO_Home.aDTO_PlantillaSeccion[i].DTO_Seccion;
              break;
            case 5:
              this.aDTO_ConocenosTexto = this.aDTO_Home.aDTO_PlantillaSeccion[i].DTO_Seccion;
              break;
            case 6:
              this.aDTO_DobleSeccion = this.aDTO_Home.aDTO_PlantillaSeccion[i].DTO_Seccion;
              break;
            case 7:
              this.aDTO_TripleSeccion = this.aDTO_Home.aDTO_PlantillaSeccion[i].DTO_Seccion;
              break;
            default:
              break;
          }
        }
        const s_ImagenRuta = `${this.s_Basehref}${this.aDTO_Home.s_CeoReservado3}`;
        this.ats_SEO.cf_SEO(this.s_Basehref + this.ats_Router.url, this.aDTO_Home.s_CeoReservado1, s_ImagenRuta, this.aDTO_Home.s_CeoReservado2);
      }
    });
  }

  cf_Read() {
    this.ats_HttpCms.cf_ReadPC('SitioWebES', this.aDTO_SitioWeb).subscribe(Item => {
      if (Item !== null) {
        this.aDTO_SitioWeb = Item as SitioWebDTO;
        if (isPlatformBrowser(this.platformId)) {
           sessionStorage.setItem('sitio', JSON.stringify(this.aDTO_SitioWeb));
        }
        this.aDTO_Home = new PlantillaDTO(this.aDTO_SitioWeb.i_IdPlantillaHome);
        this.cf_CargaPagina();
      }
    });
  }
}
