import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { HttpClientService } from '../Common/Servicios/http-client.service';
import { SeoService } from '../Generales/servicios/seo.service';

@Component({
  selector: 'app-multimedia',
  templateUrl: './multimedia.component.html',
  styleUrls: ['./multimedia.component.css']
})
export class MultimediaComponent implements OnInit {
  href: string;
  s_Basehref: string;
  s_UrlActual: string;

  constructor(
    private router: Router,
    private title: Title,
    private meta: Meta,
    private ats_HttpCms: HttpClientService,
    private ats_SEO: SeoService,) { }

  ngOnInit() {
    this.s_Basehref = this.ats_SEO.cf_GetUrl();
    this.s_UrlActual = this.router.url;
    this.ats_SEO.cf_SEO(this.s_Basehref + this.router.url,
    'Ubicanos - Parques Industriales', this.s_Basehref + '/assets/image/Parques-Industriales/Home/Home_ParqueSantin.jpg', 'En nuestros Parques Industriales encontrarás la mejor ubicación y vías de acceso.');
 
  }

 

}
