import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { SeoService } from '../Generales/servicios/seo.service';

@Component({
  selector: 'app-renta-intermedia',
  templateUrl: './renta-intermedia.component.html',
  styleUrls: ['./renta-intermedia.component.css']
})
export class RentaIntermediaComponent implements OnInit {

  i_WindowWidth = 0;
  s_Estilo = "";
  s_ImagenPrincipal = '';
  s_ImagenArrLocales = '';
  s_ImagenRentaEspacios = '';
  s_Lat = '';
  s_Lng = '';
  s_IframeUrl: any;
  s_Basehref: string;
  s_UrlActual: any;
  s_Url: string;
  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    private ats_DomSanitizer: DomSanitizer,
    private ats_SEO: SeoService,
    private router: Router ) {
    if (isPlatformBrowser(this.platformId)) {
      this.i_WindowWidth = window.screen.width;
    }}

  ngOnInit() {
    this.s_Basehref = this.ats_SEO.cf_GetUrl();
    this.s_UrlActual = this.router.url;
    this.s_Url = this.s_Basehref + this.s_UrlActual;
    this.s_ImagenPrincipal = environment.s_RentaIntermediaPrincipal;
    this.s_ImagenArrLocales = environment.s_RentaIntermediaArrenLo;
    this.s_ImagenRentaEspacios = environment.s_RentaIntermediaEspacios;
    this.s_Lat = environment.s_Lat;
    this.s_Lng = environment.s_Lng;
    this.s_Estilo = `url('${environment.s_RentaIntermediaPrincipal}')`;
    this.s_IframeUrl = this.ats_DomSanitizer.bypassSecurityTrustResourceUrl(`https://www.google.com/maps/embed/v1/place?key=AIzaSyDyX5gIHJxMAprhQ12R8TovRn9rFOUfEIQ&center=${this.s_Lat},${this.s_Lng}&q=${environment.s_Sitio}`);

    this.ats_SEO.cf_SEO(this.s_Basehref + this.router.url, `Renta de espacios - ${environment.s_NombreSitio}` ,
      environment.s_RentaIntermediaPrincipal, 'Pagina intermedia de espacios rentables, para renta de locales y renta de espacios publicitarios');
  }

}
