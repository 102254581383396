import { Component, OnInit, Inject, PLATFORM_ID, Injector, ViewChild, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ModalImagenComponent } from '../Generales/modales/imgmodal/modalimagen';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpClientService } from '../Common/Servicios/http-client.service';
import { PlantillaDTO } from '../Common/Models/PlantillaDTO';
import { PlazaContratoDTO } from '../Common/Models/PlazaContratoDTO';
import { SeccionImagenDTO } from '../Common/Models/SeccionImagenDTO';
import { SeoService } from '../Generales/servicios/seo.service';
import { isPlatformBrowser } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-detalle-tienda-basico',
  templateUrl: './detalle-tienda-basico.component.html'
})
export class DetalleTiendaBasicoComponent implements OnInit {
  public href: string = '';
  base_Href: string = '';
  i_IdLocal:number = 0;
  aDTO_PlantillaTienda: PlantillaDTO = new PlantillaDTO();
  aDTO_Contrato: PlazaContratoDTO = new PlazaContratoDTO();
  aDTO_TiendaPrincipal: SeccionImagenDTO[];
  aDTO_TiendaLogo: SeccionImagenDTO[];
  aDTO_TiendaTriple: SeccionImagenDTO[];
  s_TextoMsj = environment.s_TextoMsj;
  s_Tags = environment.s_Tags;
  s_UrlImagen = '';
  s_UrlPlano = '';
  s_Logo = '';
  btnfacebook: any;
  constructor(public dialog: MatDialog,
     private ats_Router: Router,
     private ats_HttpCms: HttpClientService,
     private RutaActiva: ActivatedRoute,
     private ats_SEO: SeoService,
     @Inject(PLATFORM_ID) private platformId: Object,
     private ats_DomSanitizer: DomSanitizer
    ) {
      this.RutaActiva.params.subscribe((ae_UrlParams: Params) => {
        if (ae_UrlParams.i_IdPlantilla !== null && ae_UrlParams.i_IdPlantilla !== undefined && ae_UrlParams.i_IdPlantilla !== 0) {
          this.aDTO_PlantillaTienda = new PlantillaDTO(ae_UrlParams.i_IdPlantilla);
          this.cf_ReadPlantilla();
        } else {
          this.ats_Router.navigateByUrl('/todas-las-tiendas');
        }
      });
  }

  ngOnInit() {
    this.base_Href = this.ats_SEO.cf_GetUrl();
    this.href = this.base_Href + this.ats_Router.url;
    // tslint:disable-next-line: max-line-length
  }

  cf_AbrirImagen(s_UrlImagen: string): void {
    const dialogRef = this.dialog.open(ModalImagenComponent, {
      width: 'auto',
      maxWidth: 'auto',
      height: 'auto',
      maxHeight: '90vh',
      data: {s_UrlImagen: s_UrlImagen}
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  cf_ReadPlantilla() {
    const aParameters = {
      i_IdSitioWeb: environment.i_IdSitioweb
    };
    this.ats_HttpCms.cf_ReadPC('PlantillaES', this.aDTO_PlantillaTienda, 11, aParameters).subscribe(Item => {
      if (Item !== null ) {
        this.aDTO_PlantillaTienda = Item as PlantillaDTO;
        for (let i = 0; i < this.aDTO_PlantillaTienda.aDTO_PlantillaSeccion.length; i++) {
          switch (this.aDTO_PlantillaTienda.aDTO_PlantillaSeccion[i].DTO_Seccion.i_IdUbicacion) {
            case 1:
              this.aDTO_TiendaPrincipal = this.aDTO_PlantillaTienda.aDTO_PlantillaSeccion[i].DTO_Seccion.aDTO_SeccionImagen;
              break;
            case 2:
              this.aDTO_TiendaLogo = this.aDTO_PlantillaTienda.aDTO_PlantillaSeccion[i].DTO_Seccion.aDTO_SeccionImagen;
              this.s_Logo = this.aDTO_TiendaLogo[0].DTO_Imagen.s_Ubicacion + '/' +this.aDTO_TiendaLogo[0].DTO_Imagen.s_Nombre;
              break;
            case 3:
              this.aDTO_TiendaTriple = this.aDTO_PlantillaTienda.aDTO_PlantillaSeccion[i].DTO_Seccion.aDTO_SeccionImagen;
              break;
          }
        }
        if (this.aDTO_PlantillaTienda.s_JsonObject !== '') {
           const s_Contrato = JSON.parse(this.aDTO_PlantillaTienda.s_JsonObject);
           this.aDTO_Contrato = new PlazaContratoDTO(s_Contrato.i_IdPlaza, s_Contrato.i_IdContrato);
           this.cf_ReadContrato();
           if (environment.i_IdPlaza !== s_Contrato.i_IdPlaza) {
            this.ats_Router.navigateByUrl('error');
          }
        }
        const s_ImagenRuta = `${this.base_Href}${this.aDTO_PlantillaTienda.s_CeoReservado3}`;
        this.s_UrlImagen = s_ImagenRuta;
        this.ats_SEO.cf_SEO(this.href,
          this.aDTO_PlantillaTienda.s_CeoReservado1,
          s_ImagenRuta, this.aDTO_PlantillaTienda.s_CeoReservado2);
      } else {
        this.ats_Router.navigateByUrl('error');
      }
    });
  }

  cf_ReadContrato() {
    this.ats_HttpCms.cf_ReadPC('PlazaContratoES', this.aDTO_Contrato, 11).subscribe(Item => {
      if (Item !== null) {
        this.aDTO_Contrato = Item as PlazaContratoDTO;
        this.i_IdLocal = this.aDTO_Contrato.i_TarjetaIdPoligono;
        this.s_UrlPlano = this.aDTO_Contrato.DTO_InmuebleNivel.s_PlanogramaRuta;
      }
    });
  }


}
