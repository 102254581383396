﻿/*
 * Copyright © 2012-2021 Grupo Frisa and its subsidiaries.
 * Version Generador: 2.3.4.0 - Angular TypeScript
 */

import { BaseDTO } from '../BaseClasses/Pattern/BaseDTO';

import { PlantillaSeccionDTO } from './PlantillaSeccionDTO';

/**
 * **PlantillaDTO**

 * Llave Primaria: 
 * - i_IdPlantilla

 * Dependientes (DTO): 
 * - aDTO_PlantillaSeccion
 */
export class PlantillaDTO extends BaseDTO {
    public i_IdPlantilla: number;
    public i_IdTipoPlantilla: number;
    public s_Titulo: string;
    public s_UrlRelativa: string;
    public i_Secciones: number;
    public s_CeoReservado1: string;
    public s_CeoReservado2: string;
    public s_CeoReservado3: string;
    public s_CeoReservado4: string;

    /*
     * Entidades que dependen de PlantillaDTO
     */
    public aDTO_PlantillaSeccion: PlantillaSeccionDTO[];

    /**
     * **constructor**
     *
     * Crea los DTOs (FKs) de la instancia e inicializa atributos
     * @param ai_IdPlantilla number
     * @param ab_SetupDTO Boolean
     * @returns void
     */
    constructor(ai_IdPlantilla: number = 0, ab_SetupDTO: Boolean = true) {
        super();

        this.cf_CreateLevelDTO(ab_SetupDTO);

        if (ab_SetupDTO) { this.cf_SetupDTO(); }

        this.i_IdPlantilla = ai_IdPlantilla;
    }

    /**
     * **cf_CreateLevelDTO**
     *
     * Esta funcion no debe de ser SobreEscrita.
     *
     * Crea un DTO hasta el nivel solicitado (llaves foraneas).
     *
     * Inicializa sus propiedades si es que fue requerido.
     * @param ab_SetupDTO Indica si se inicializaran las variables del DTO
     * @returns void
     */
    public cf_CreateLevelDTO(ab_SetupDTO: Boolean): void {
    }

    /**
     * **cf_SetupDTO**
     *
     * 	Inicializa todos los atributos del DTO, excepto la PK
     * @returns void
     */
    public cf_SetupDTO(): void {
        this.i_IdTipoPlantilla = 0;
        this.s_Titulo = '';
        this.s_UrlRelativa = '';
        this.i_Secciones = 0;
        this.s_CeoReservado1 = '';
        this.s_CeoReservado2 = '';
        this.s_CeoReservado3 = '';
        this.s_CeoReservado4 = '';
    }

    /**
     * **cf_Clone**
     *
     * Esta funcion no deberia de ser SobreEscrita.
     *
     * 	Clona el DTO (mismos atributos y estado), regresa un DTO de tipo PlantillaDTO.
     * @returns PlantillaDTO
     */
    public cf_Clone(): BaseDTO {
        let lDT_New: PlantillaDTO;

        lDT_New = new PlantillaDTO(null, false);
        lDT_New.e_CloneAttributes(this);

        return lDT_New;
    }

    /**
     * **cf_SamePK**
     *
     * Esta funcion no deberia de ser SobreEscrita.
     *
     * 	Revisa si la llave primaria de este DTO es igual a la del argumento (PlantillaDTO).
     * @param aDT_Entity El DTO contra el que se va a comparar la PK
     * @returns Boolean
     */
    public cf_SamePK(aDT_Entity: BaseDTO): Boolean {
        let lDT_Entity: PlantillaDTO;

        lDT_Entity = aDT_Entity as PlantillaDTO;

        if (this.i_IdPlantilla === null || lDT_Entity.i_IdPlantilla === null) return false;
        if (this.i_IdPlantilla !== lDT_Entity.i_IdPlantilla) return false;

        return true;
    }

    /**
     * **e_CloneAttributes**
     *
     * Este evento podria ser SobreCargado (en caso de que se declaren variables en el DTO).
     *
     * 	Copia los valores de todos los atributos del DTO Original a este que es el Clon
     * @param aDTO_Original PlantillaDTO
     * @returns Nada
     */
    public e_CloneAttributes(aDTO_Original: BaseDTO): void {
        let lDTO_Original: PlantillaDTO;

        super.e_CloneAttributes(aDTO_Original);

        lDTO_Original = aDTO_Original as PlantillaDTO;

        this.i_IdPlantilla = lDTO_Original.i_IdPlantilla;
        this.i_IdTipoPlantilla = lDTO_Original.i_IdTipoPlantilla;
        this.s_Titulo = lDTO_Original.s_Titulo;
        this.s_UrlRelativa = lDTO_Original.s_UrlRelativa;
        this.i_Secciones = lDTO_Original.i_Secciones;
        this.s_CeoReservado1 = lDTO_Original.s_CeoReservado1;
        this.s_CeoReservado2 = lDTO_Original.s_CeoReservado2;
        this.s_CeoReservado3 = lDTO_Original.s_CeoReservado3;
        this.s_CeoReservado4 = lDTO_Original.s_CeoReservado4;
    }
}
