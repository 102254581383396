
import {MatButtonModule,
    MatCheckboxModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatFormFieldModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    MatExpansionModule,
    MatDialogModule,
    MatRippleModule,
    MatGridListModule,
} from '@angular/material';
import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
@NgModule({
  declarations: [ ],
  imports: [MatButtonModule,
    MatCheckboxModule,
    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatFormFieldModule,
    MatInputModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    MatExpansionModule,
    MatDialogModule,
    MatRippleModule,
    MatProgressSpinnerModule,
    MatGridListModule,
    MatCardModule,
],
  exports: [
     MatButtonModule,
     MatCheckboxModule,
     MatToolbarModule,
     MatSidenavModule,
     MatIconModule,
     MatListModule,
     MatFormFieldModule,
     MatInputModule,
     MatFormFieldModule,
     MatOptionModule,
     MatSelectModule,
     MatExpansionModule,
     MatDialogModule,
     MatRippleModule,
     MatProgressSpinnerModule,
     MatGridListModule,
     MatCardModule,
    ],
})
export class MaterialModule {
}
