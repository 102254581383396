import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { HttpClientService } from '../Common/Servicios/http-client.service';
import { PlantillaDTO } from '../Common/Models/PlantillaDTO';
import { SeccionImagenDTO } from '../Common/Models/SeccionImagenDTO';
import { MatDialog } from '@angular/material';
import { ModalImagenComponent } from '../Generales/modales/imgmodal/modalimagen';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SeoService } from '../Generales/servicios/seo.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-detalle-tienda-completo',
  templateUrl: './detalle-tienda-completo.component.html'
})
export class DetalleTiendaCompletoComponent implements OnInit {
  aDTO_PlantillaTienda: PlantillaDTO = new PlantillaDTO(35);
  aDTO_DetallePrincipal: SeccionImagenDTO[] = null;
  aDTO_DetalleLogo: SeccionImagenDTO[] = null;
  aDTO_DetalleImagenes: SeccionImagenDTO[] = null;
  aDTO_DetallePromociones: SeccionImagenDTO[] = null;
  href: string = '';
  base_Href: string = '';

  constructor(private ats_HttpCms: HttpClientService,public dialog: MatDialog,
    private RutaActiva: ActivatedRoute,
    private ats_SEO: SeoService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private ats_Router: Router,) { }

  ngOnInit() {
    this.base_Href = this.ats_SEO.cf_GetUrl();
    this.href = this.base_Href + this.ats_Router.url;
    this.RutaActiva.params.subscribe((ae_UrlParams: Params) => {
      if (ae_UrlParams.i_IdPlantilla !== null && ae_UrlParams.i_IdPlantilla !== undefined && ae_UrlParams.i_IdPlantilla !== 0) {
        this.aDTO_PlantillaTienda = new PlantillaDTO(ae_UrlParams.i_IdPlantilla);
        this.cf_ReadPlantilla();
      }
    });
  }

  cf_ReadPlantilla() {
    const aParameters = {
      i_IdSitioWeb: environment.i_IdSitioweb
    };
    this.ats_HttpCms.cf_ReadPC('PlantillaES', this.aDTO_PlantillaTienda, 11, aParameters).subscribe(Item => {
      if (Item !== null) {
        this.aDTO_PlantillaTienda = Item as PlantillaDTO;
        for (let i = 0; i < this.aDTO_PlantillaTienda.aDTO_PlantillaSeccion.length; i++) {
          switch (this.aDTO_PlantillaTienda.aDTO_PlantillaSeccion[i].DTO_Seccion.i_IdUbicacion) {
            case 1:
              this.aDTO_DetallePrincipal = this.aDTO_PlantillaTienda.aDTO_PlantillaSeccion[i].DTO_Seccion.aDTO_SeccionImagen;
              break;
            case 2:
              this.aDTO_DetalleLogo = this.aDTO_PlantillaTienda.aDTO_PlantillaSeccion[i].DTO_Seccion.aDTO_SeccionImagen;
              break;
            case 3:
              this.aDTO_DetalleImagenes = this.aDTO_PlantillaTienda.aDTO_PlantillaSeccion[i].DTO_Seccion.aDTO_SeccionImagen;
              break;
            case 4:
              this.aDTO_DetallePromociones =this.aDTO_PlantillaTienda.aDTO_PlantillaSeccion[i].DTO_Seccion.aDTO_SeccionImagen;
              break;
            default:
              break;
          }
        }
        const s_ImagenRuta = `${this.base_Href}${this.aDTO_PlantillaTienda.s_CeoReservado3}`;
        this.ats_SEO.cf_SEO(this.href,
          this.aDTO_PlantillaTienda.s_CeoReservado1,
          s_ImagenRuta, this.aDTO_PlantillaTienda.s_CeoReservado2);
      }
    });
  }

  cf_AbrirImagen(s_UrlImagen: string): void {
    const dialogRef = this.dialog.open(ModalImagenComponent, {
      width: '60%',
      maxWidth: '500px',
      height: 'auto',
      maxHeight: '80vh',
      data: {s_UrlImagen: s_UrlImagen}
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }


}
