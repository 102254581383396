﻿/*
 * Copyright © 2012-2021 Grupo Frisa and its subsidiaries.
 * Version Generador: 2.3.4.0 - Angular TypeScript
 */

import { BaseDTO } from '../BaseClasses/Pattern/BaseDTO';

import { SeccionImagenDTO } from './SeccionImagenDTO';

/**
 * **ImagenDTO**

 * Llave Primaria: 
 * - i_IdImagen

 * Dependientes (DTO): 
 * - aDTO_SeccionImagen
 */
export class ImagenDTO extends BaseDTO {
    public i_IdImagen: number;
    public s_TextoActivo: string;
    public s_TextoPrincipal: string;
    public s_TextoAternativo01: string;
    public s_TextoAternativo02: string;
    public s_TextoAternativo03: string;
    public s_UrlActivo: string;
    public s_UrlTexto: string;
    public s_UrlValor: string;
    public i_Alto: number;
    public i_Ancho: number;
    public i_Magnitud: number;
    public s_Ubicacion: string;
    public s_Nombre: string;
    public i_IdPlantilla: number;
    public i_Ordenacion: number;

    /*
     * Entidades que dependen de ImagenDTO
     */
    public aDTO_SeccionImagen: SeccionImagenDTO[];

    /**
     * **constructor**
     *
     * Crea los DTOs (FKs) de la instancia e inicializa atributos
     * @param ai_IdImagen number
     * @param ab_SetupDTO Boolean
     * @returns void
     */
    constructor(ai_IdImagen: number = 0, ab_SetupDTO: Boolean = true) {
        super();

        this.cf_CreateLevelDTO(ab_SetupDTO);

        if (ab_SetupDTO) { this.cf_SetupDTO(); }

        this.i_IdImagen = ai_IdImagen;
    }

    /**
     * **cf_CreateLevelDTO**
     *
     * Esta funcion no debe de ser SobreEscrita.
     *
     * Crea un DTO hasta el nivel solicitado (llaves foraneas).
     *
     * Inicializa sus propiedades si es que fue requerido.
     * @param ab_SetupDTO Indica si se inicializaran las variables del DTO
     * @returns void
     */
    public cf_CreateLevelDTO(ab_SetupDTO: Boolean): void {
    }

    /**
     * **cf_SetupDTO**
     *
     * 	Inicializa todos los atributos del DTO, excepto la PK
     * @returns void
     */
    public cf_SetupDTO(): void {
        this.s_TextoActivo = '';
        this.s_TextoPrincipal = '';
        this.s_TextoAternativo01 = '';
        this.s_TextoAternativo02 = '';
        this.s_TextoAternativo03 = '';
        this.s_UrlActivo = '';
        this.s_UrlTexto = '';
        this.s_UrlValor = '';
        this.i_Alto = 0;
        this.i_Ancho = 0;
        this.i_Magnitud = 0;
        this.s_Ubicacion = '';
        this.s_Nombre = '';
        this.i_IdPlantilla = 0;
        this.i_Ordenacion = 0;
    }

    /**
     * **cf_Clone**
     *
     * Esta funcion no deberia de ser SobreEscrita.
     *
     * 	Clona el DTO (mismos atributos y estado), regresa un DTO de tipo ImagenDTO.
     * @returns ImagenDTO
     */
    public cf_Clone(): BaseDTO {
        let lDT_New: ImagenDTO;

        lDT_New = new ImagenDTO(null, false);
        lDT_New.e_CloneAttributes(this);

        return lDT_New;
    }

    /**
     * **cf_SamePK**
     *
     * Esta funcion no deberia de ser SobreEscrita.
     *
     * 	Revisa si la llave primaria de este DTO es igual a la del argumento (ImagenDTO).
     * @param aDT_Entity El DTO contra el que se va a comparar la PK
     * @returns Boolean
     */
    public cf_SamePK(aDT_Entity: BaseDTO): Boolean {
        let lDT_Entity: ImagenDTO;

        lDT_Entity = aDT_Entity as ImagenDTO;

        if (this.i_IdImagen === null || lDT_Entity.i_IdImagen === null) return false;
        if (this.i_IdImagen !== lDT_Entity.i_IdImagen) return false;

        return true;
    }

    /**
     * **e_CloneAttributes**
     *
     * Este evento podria ser SobreCargado (en caso de que se declaren variables en el DTO).
     *
     * 	Copia los valores de todos los atributos del DTO Original a este que es el Clon
     * @param aDTO_Original ImagenDTO
     * @returns Nada
     */
    public e_CloneAttributes(aDTO_Original: BaseDTO): void {
        let lDTO_Original: ImagenDTO;

        super.e_CloneAttributes(aDTO_Original);

        lDTO_Original = aDTO_Original as ImagenDTO;

        this.i_IdImagen = lDTO_Original.i_IdImagen;
        this.s_TextoActivo = lDTO_Original.s_TextoActivo;
        this.s_TextoPrincipal = lDTO_Original.s_TextoPrincipal;
        this.s_TextoAternativo01 = lDTO_Original.s_TextoAternativo01;
        this.s_TextoAternativo02 = lDTO_Original.s_TextoAternativo02;
        this.s_TextoAternativo03 = lDTO_Original.s_TextoAternativo03;
        this.s_UrlActivo = lDTO_Original.s_UrlActivo;
        this.s_UrlTexto = lDTO_Original.s_UrlTexto;
        this.s_UrlValor = lDTO_Original.s_UrlValor;
        this.i_Alto = lDTO_Original.i_Alto;
        this.i_Ancho = lDTO_Original.i_Ancho;
        this.i_Magnitud = lDTO_Original.i_Magnitud;
        this.s_Ubicacion = lDTO_Original.s_Ubicacion;
        this.s_Nombre = lDTO_Original.s_Nombre;
        this.i_IdPlantilla = lDTO_Original.i_IdPlantilla;
        this.i_Ordenacion = lDTO_Original.i_Ordenacion;
    }
}
