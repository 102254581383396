import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { ModalEventoComponent } from '../Generales/modales/eventomodal/modalevento';
import { MatDialog } from '@angular/material';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpClientService } from '../Common/Servicios/http-client.service';
import { PlantillaDTO } from '../Common/Models/PlantillaDTO';
import { SeccionDTO } from '../Common/Models/SeccionDTO';
import { SitioWebDTO } from '../Common/Models/SitioWebDTO';
import { isPlatformBrowser } from '@angular/common';
import { SeoService } from '../Generales/servicios/seo.service';
import { environment} from '../../environments/environment';

@Component({
  selector: 'app-eventos',
  templateUrl: './eventos.component.html'
})
export class EventosComponent implements OnInit {
  href: string;
  aDTO_PlantillaEventos: PlantillaDTO;
  aDTO_Principal: SeccionDTO = null;
  aDTO_ProximoEventos: SeccionDTO = null;
  aDTO_EventosActuales: SeccionDTO = null;
  aDTO_EventosAnteriores: SeccionDTO = null;
  aDTO_SitioWeb: SitioWebDTO = null;
  s_Basehref: string;
  constructor(public dialog: MatDialog,
    private ats_Router: Router,
    private ats_HttpCms: HttpClientService,
    private RutaActiva: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: string,
    private ats_SEO: SeoService,) {
      if (isPlatformBrowser(this.platformId)) {
      this.aDTO_SitioWeb = JSON.parse(sessionStorage.getItem('sitio'));
      }
     }

  ngOnInit() {
    this.s_Basehref = this.ats_SEO.cf_GetUrl();
    this.RutaActiva.params.subscribe((ae_UrlParams: Params) => {
      if (ae_UrlParams.i_IdEvento !== null && ae_UrlParams.i_IdEvento !== undefined && ae_UrlParams.i_IdEvento !== 0) {
        setTimeout(() => {
          this.cf_AbrirEvento(ae_UrlParams.i_IdEvento);
        }, 500);
      }
    });
    if (this.aDTO_SitioWeb !== null) {
      this.aDTO_PlantillaEventos = new PlantillaDTO(this.aDTO_SitioWeb.i_IdPlantillaEventos);
      this.cf_ReadPlantilla();
     } else {
       this.aDTO_SitioWeb = new SitioWebDTO(environment.i_IdSitioweb);
       this.cf_Read();
     }
  }


  cf_ReadPlantilla() {
    const aParameters = {
      i_IdSitioWeb: environment.i_IdSitioweb
    };
    this.ats_HttpCms.cf_ReadPC('PlantillaES', this.aDTO_PlantillaEventos, 11, aParameters).subscribe(Item => {
      if (Item !== null) {
        this.aDTO_PlantillaEventos = Item as PlantillaDTO;
        for (let i = 0; i < this.aDTO_PlantillaEventos.aDTO_PlantillaSeccion.length; i++) {
          switch (this.aDTO_PlantillaEventos.aDTO_PlantillaSeccion[i].DTO_Seccion.i_IdUbicacion) {
            case 1:
              this.aDTO_Principal = this.aDTO_PlantillaEventos.aDTO_PlantillaSeccion[i].DTO_Seccion;
              break;
            case 2:
              this.aDTO_ProximoEventos = this.aDTO_PlantillaEventos.aDTO_PlantillaSeccion[i].DTO_Seccion;
              break;
            case 3:
              this.aDTO_EventosActuales = this.aDTO_PlantillaEventos.aDTO_PlantillaSeccion[i].DTO_Seccion;
              break;
            case 4:
              this.aDTO_EventosAnteriores = this.aDTO_PlantillaEventos.aDTO_PlantillaSeccion[i].DTO_Seccion;
              break;
            default:
              break;
          }
        }
        const s_ImagenRuta = `${this.s_Basehref}${this.aDTO_PlantillaEventos.s_CeoReservado3}`;
        this.ats_SEO.cf_SEO(this.s_Basehref + this.ats_Router.url, this.aDTO_PlantillaEventos.s_CeoReservado1, s_ImagenRuta, this.aDTO_PlantillaEventos.s_CeoReservado2);
      }
    });
  }


  cf_AbrirEvento(i_IdSeccion: number): void {
    const dialogRef = this.dialog.open(ModalEventoComponent, {
      width: '80%',
      maxWidth: '1200px',
      height: 'auto',
      maxHeight: '80vh',
      data: {i_IdSeccion: i_IdSeccion, s_Tipo: 'evento'}
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }



  cf_Read() {
    this.ats_HttpCms.cf_ReadPC('SitioWebES', this.aDTO_SitioWeb).subscribe(Item => {
      if (Item !== null) {
        this.aDTO_SitioWeb = Item as SitioWebDTO;
        if (isPlatformBrowser(this.platformId)) {
          sessionStorage.setItem('sitio', JSON.stringify(this.aDTO_SitioWeb));
        }
        this.aDTO_PlantillaEventos = new PlantillaDTO(this.aDTO_SitioWeb.i_IdPlantillaEventos);
        this.cf_ReadPlantilla();
      }
    });
  }
}
