import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { HttpClientService } from '../Common/Servicios/http-client.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { PlantillaDTO } from '../Common/Models/PlantillaDTO';
import { SeccionImagenDTO } from '../Common/Models/SeccionImagenDTO';
import { SeccionDTO } from '../Common/Models/SeccionDTO';
import { ImagenDTO } from '../Common/Models/ImagenDTO';
import { SeoService } from '../Generales/servicios/seo.service';
import { PlazaGiroDTO } from '../Common/Models/PlazaGiroDTO';
import { environment } from '../../environments/environment';
import { isPlatformBrowser } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-buscar-tienda',
  templateUrl: './buscar-tienda.component.html'
})
export class BuscarTiendaComponent implements OnInit, OnDestroy {
  aDTO_Tiendas: SeccionImagenDTO[] = [];
  aDatos_Tienda: SeccionImagenDTO[] = [];
  aDTO_PlantillaBuscar: PlantillaDTO = new PlantillaDTO(5);
  aDTO_Giros: PlazaGiroDTO[];
  s_Filtro = '';
  s_regexp = / /gi;
  href: string;
  lb_Mostrar = false;
  aDTO_SeccionEventos: SeccionDTO = null;
  aDTO_SeccionPromociones: SeccionDTO = null;
  i_IdCategoria = 90;
  s_Basehref: string;
  s_UrlActual: string = '';
  s_JsonLD: any;

  constructor(private ats_HttpCms: HttpClientService,
    private ats_Router: Router,
    private RutaActiva: ActivatedRoute,
    private ats_SEO: SeoService,
    @Inject(PLATFORM_ID) private platformId: string,
    private ats_DomSanitizer: DomSanitizer) {
    this.s_UrlActual = this.ats_Router.url;
    RutaActiva.params.subscribe((ae_UrlParams: Params) => {
      if (ae_UrlParams.i_IdPlantilla !== null && ae_UrlParams.i_IdPlantilla !== undefined && ae_UrlParams.i_IdPlantilla !== 0) {
        this.aDTO_PlantillaBuscar = new PlantillaDTO(ae_UrlParams.i_IdPlantilla);
      }
      if (isPlatformBrowser(this.platformId)) {
        switch (this.s_UrlActual) {
          case '/todas-las-tiendas/5':
          case '/todas-las-tiendas/1013':
            const s_BusquedaTodas = JSON.parse(sessionStorage.getItem('busquedaTodasTiendas'));
            const i_Categoria = JSON.parse(sessionStorage.getItem('categoria'));
            if (i_Categoria !== null && i_Categoria !== 90) {
              this.i_IdCategoria = i_Categoria;
            }
            if (s_BusquedaTodas !== null && s_BusquedaTodas !== '') {
              this.s_Filtro = s_BusquedaTodas;
            }
            break;
          case '/todas-las-tiendas/9':
          case 'todas-las-tiendas/1014':
            const s_BusquedaEntre = JSON.parse(sessionStorage.getItem('busquedaEntre'));
            if (s_BusquedaEntre !== null && s_BusquedaEntre !== '') {
              this.s_Filtro = s_BusquedaEntre;
            }
            break;
          case '/todas-las-tiendas/10':
          case '/todas-las-tiendas/1015':
            const s_BusquedaResta = JSON.parse(sessionStorage.getItem('busquedaRes'));
            if (s_BusquedaResta !== null && s_BusquedaResta !== '') {
              this.s_Filtro = s_BusquedaResta;
            }
            break;
          default:
            break;
        }
      }
      if (ae_UrlParams.s_Busqueda !== null && ae_UrlParams.s_Busqueda !== undefined && ae_UrlParams.s_Busqueda !== 0) {
        this.s_Filtro = ae_UrlParams.s_Busqueda;
      }
    });
  }

  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId)) {
      switch (this.s_UrlActual) {
        case '/todas-las-tiendas/5':
          case '/todas-las-tiendas/1013':
          sessionStorage.setItem('busquedaTodasTiendas', JSON.stringify(this.s_Filtro));
          sessionStorage.setItem('categoria', JSON.stringify(this.i_IdCategoria));
          break;
          case '/todas-las-tiendas/9':
            case 'todas-las-tiendas/1014':
          sessionStorage.setItem('busquedaEntre', JSON.stringify(this.s_Filtro));
          break;
          case '/todas-las-tiendas/10':
            case '/todas-las-tiendas/1015':
          sessionStorage.setItem('busquedaRes', JSON.stringify(this.s_Filtro));
          break;
        default:
          break;
      }

    }
  }

  ngOnInit() {
    this.aDTO_Tiendas = [];
    this.cf_LoadTiendas();
    this.s_Basehref = this.ats_SEO.cf_GetUrl();
    this.cf_LoadCatalogoValor();
   const s_Json = `
   <script type="application/ld+json">
       {
           "@context": "https://schema.org",
           "@type": "WebSite",
           "url": "https://operaciones.grupofrisa.com/todas-las-tiendas/5/",
           "potentialAction": {
               "@type": "SearchAction",
               "target": "https://operaciones.grupofrisa.com/todas-las-tiendas/5/{search_term_string}",
               "query-input": "required name=search_term_string"
           }
       }
   </script>`;
    this.s_JsonLD  = this.ats_DomSanitizer.bypassSecurityTrustHtml(s_Json);
  }


  cf_LoadTiendas() {
    const aParameters = {
      i_IdSitioWeb: environment.i_IdSitioweb
    };
    this.ats_HttpCms.cf_ReadPC('PlantillaES', this.aDTO_PlantillaBuscar, 11, aParameters).subscribe(Item => {
      if (Item !== null) {
        this.aDTO_PlantillaBuscar = Item as PlantillaDTO;
        for (let i = 0; i < this.aDTO_PlantillaBuscar.aDTO_PlantillaSeccion.length; i++) {
          switch (this.aDTO_PlantillaBuscar.aDTO_PlantillaSeccion[i].DTO_Seccion.i_IdUbicacion) {
            case 2:
              this.aDTO_SeccionEventos = this.aDTO_PlantillaBuscar.aDTO_PlantillaSeccion[i].DTO_Seccion;
              break;
            case 3:
              this.aDTO_SeccionPromociones = this.aDTO_PlantillaBuscar.aDTO_PlantillaSeccion[i].DTO_Seccion;
              break;
          }
        }
        this.aDTO_Tiendas = this.aDTO_PlantillaBuscar.aDTO_PlantillaSeccion[0].DTO_Seccion.aDTO_SeccionImagen;
        for (let i = 0; i < this.aDTO_Tiendas.length; i++) {
          if (i < 70) {
            this.aDTO_Tiendas[i].Tag = 'S';
          } else {
            this.aDTO_Tiendas[i].Tag = 'N';
          }
        }

        if (this.aDTO_Tiendas.length < 70) {
          this.lb_Mostrar = false;
        } else {
          this.lb_Mostrar = true;
        }
        this.aDTO_Tiendas = this.aDTO_Tiendas;
        this.aDatos_Tienda = this.aDTO_PlantillaBuscar.aDTO_PlantillaSeccion[0].DTO_Seccion.aDTO_SeccionImagen;
        this.cf_Mostrar('S');
        const s_ImagenRuta = `${this.s_Basehref}${this.aDTO_PlantillaBuscar.s_CeoReservado3}`;
        this.ats_SEO.cf_SEO(this.s_Basehref + this.ats_Router.url,
          this.aDTO_PlantillaBuscar.s_CeoReservado1, s_ImagenRuta, this.aDTO_PlantillaBuscar.s_CeoReservado2);
        if (this.s_UrlActual === '/todas-las-tiendas/5' && this.i_IdCategoria !== 90) {
          this.cf_Categoria(this.i_IdCategoria);
        } else if ( this.s_Filtro !== '') {
          this.cf_Buscar(this.s_Filtro);
        }
      }
    });
  }

  cf_Buscar(s_FiltroBusqueda: string) {
    if (this.i_IdCategoria === 90) {
      let aDToTiendas = this.aDTO_Tiendas;
      if (s_FiltroBusqueda === '') {
        if (this.aDTO_Tiendas.length < 70) {
          this.lb_Mostrar = false;
        } else {
          this.lb_Mostrar = true;
        }
        aDToTiendas = this.aDTO_Tiendas.filter((s) => {
          return s.Tag.includes('S', 0);
        });
      } else {
        this.lb_Mostrar = false;
      }
      this.aDatos_Tienda = Object.assign(aDToTiendas);
      this.aDatos_Tienda =
        this.aDatos_Tienda.filter((s) => {
          return s.DTO_Imagen.s_UrlTexto.trim().toLowerCase().includes(s_FiltroBusqueda.trim().toLowerCase(), 0);
        });
    } else {
      const aDToTiendas = this.aDTO_Tiendas;
      let s_Categoria = '';
      if (this.i_IdCategoria === 90) {
        s_Categoria = 'all';
      } else {
        s_Categoria = '||' + this.i_IdCategoria;
      }
      this.lb_Mostrar = false;
      this.aDatos_Tienda = Object.assign(aDToTiendas);
      this.aDatos_Tienda =
        this.aDatos_Tienda.filter((s) => {
          return s.DTO_Imagen.s_TextoAternativo01.trim().toLowerCase().includes(s_Categoria, 0);
        });
      this.aDatos_Tienda =
        this.aDatos_Tienda.filter((s) => {
          return s.DTO_Imagen.s_UrlTexto.trim().toLowerCase().includes(s_FiltroBusqueda.trim().toLowerCase(), 0);
        });
    }
  }

  cf_Mostrar(s_Filtro: string) {
    this.aDatos_Tienda =
      this.aDTO_Tiendas.filter((s) => {
        return s.Tag.includes(s_Filtro, 0);
      });
  }

  cf_MostrarMas() {
    let contador = 0;
    for (let i = 0; i < this.aDTO_Tiendas.length; i++) {
      if (this.aDTO_Tiendas[i].Tag === 'N') {
        contador++;
        this.aDTO_Tiendas[i].Tag = 'S';
      }
      if (contador === 70) {
        break;
      }
    }

    const aDToTiendas = this.aDTO_Tiendas.filter((s) => {
      return s.Tag.includes('S', 0);
    });
    this.aDatos_Tienda = Object.assign(aDToTiendas);
    if (contador < 70) {
      this.lb_Mostrar = false;
    }
    //
    // if (this.s_Filtro === '' && this.i_IdCategoria !== 0) {
    //   this.cf_Categoria(this.i_IdCategoria);
    // } else {
    //   this.cf_Buscar(this.s_Filtro);
    // }

    document.querySelector('mat-sidenav-content').scrollTop = document.querySelector('mat-sidenav-content').scrollTop - 300;
  }

  cf_AbrirTienda(aDTO_Tienda: ImagenDTO) {
    const aParameters = {
      i_IdSitioWeb: environment.i_IdSitioweb
    };
    let aDTO_PlantillaTienda = new PlantillaDTO(aDTO_Tienda.i_IdPlantilla);
    this.ats_HttpCms.cf_ReadPC('PlantillaES', aDTO_PlantillaTienda, 11,aParameters).subscribe(Item => {
      if (Item !== null) {
        aDTO_PlantillaTienda = Item as PlantillaDTO;
        let s_Url = '';
        switch (aDTO_PlantillaTienda.i_IdTipoPlantilla) {
          case 11:
            s_Url = `/${environment.s_Tipodetalle}/${aDTO_PlantillaTienda.s_UrlRelativa}`;
            break;
          case 13:
            s_Url = `/${environment.s_TipodetalleC}/${aDTO_PlantillaTienda.s_UrlRelativa}`;
            break;
          case 15:
            break;
          default:
            break;
        }
        this.ats_Router.navigateByUrl(s_Url);
      }
    });
  }

  cf_LoadCatalogoValor() {
    const aParameters = {
      s_FilterBy: {
        i_IdPlaza: environment.i_IdPlaza,
        'IdGiro.NotIn': [2, 4]
      }
    };
    this.ats_HttpCms.cf_GetListByAction('PlazaGiroES', 1, aParameters).subscribe(ItemList => {
      if (ItemList !== null && ItemList.length > 0) {
        this.aDTO_Giros = ItemList;
      }
    });
  }

  cf_Categoria(ai_IdCategoria: any) {
    if (ai_IdCategoria === 90) {
      ai_IdCategoria = 'all';
    } else {
      ai_IdCategoria = '||' + ai_IdCategoria;
    }
    // this.s_Filtro = '';
    const aDToTiendas = this.aDTO_Tiendas;
    this.aDatos_Tienda = Object.assign(aDToTiendas);
    this.aDatos_Tienda =
      this.aDatos_Tienda.filter((s) => {
        return s.DTO_Imagen.s_TextoAternativo01.trim().toLowerCase().includes(ai_IdCategoria, 0);
      });

    if (this.s_Filtro !== '' && this.s_Filtro !== null) {
      this.aDatos_Tienda =
        this.aDatos_Tienda.filter((s) => {
          return s.DTO_Imagen.s_UrlTexto.trim().toLowerCase().includes(this.s_Filtro.trim().toLowerCase(), 0);
        });
    }
    this.lb_Mostrar = false;
  }

  cf_Nombre(s_JsonObject) {
    const DTO_Giro = JSON.parse(s_JsonObject);
    return DTO_Giro.s_IdGiro_Nombre;
  }
}
