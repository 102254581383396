﻿/*
 * Copyright © 2012-2021 Grupo Frisa and its subsidiaries.
 * Version Generador: 2.3.4.0 - Angular TypeScript
 */

import { BaseDTO } from '../BaseClasses/Pattern/BaseDTO';

import { InmuebleNivelDTO } from './InmuebleNivelDTO';

/**
 * **InmuebleDTO**

 * Llave Primaria: 
 * - i_IdRs
 * - i_IdInmueble

 * Dependientes (DTO): 
 * - aDTO_InmuebleNivel
 */
export class InmuebleDTO extends BaseDTO {
    public i_IdRs: number;
    public i_IdInmueble: number;
    public s_Nombre: string;
    public s_NombreCorto: string;
    public i_IdTipoInmueble: number;
    public i_IdGrupoEmpresarial: number;
    public s_Operacion: string;
    public i_IdPlaza: number;

    /*
     * Entidades que dependen de InmuebleDTO
     */
    public aDTO_InmuebleNivel: InmuebleNivelDTO[];

    /**
     * **constructor**
     *
     * Crea los DTOs (FKs) de la instancia e inicializa atributos
     * @param ai_IdRs number
     * @param ai_IdInmueble number
     * @param ab_SetupDTO Boolean
     * @returns void
     */
    constructor(ai_IdRs: number = 0, ai_IdInmueble: number = 0, ab_SetupDTO: Boolean = true) {
        super();

        this.cf_CreateLevelDTO(ab_SetupDTO);

        if (ab_SetupDTO) { this.cf_SetupDTO(); }

        this.i_IdRs = ai_IdRs;
        this.i_IdInmueble = ai_IdInmueble;
    }

    /**
     * **cf_CreateLevelDTO**
     *
     * Esta funcion no debe de ser SobreEscrita.
     *
     * Crea un DTO hasta el nivel solicitado (llaves foraneas).
     *
     * Inicializa sus propiedades si es que fue requerido.
     * @param ab_SetupDTO Indica si se inicializaran las variables del DTO
     * @returns void
     */
    public cf_CreateLevelDTO(ab_SetupDTO: Boolean): void {
    }

    /**
     * **cf_SetupDTO**
     *
     * 	Inicializa todos los atributos del DTO, excepto la PK
     * @returns void
     */
    public cf_SetupDTO(): void {
        this.s_Nombre = '';
        this.s_NombreCorto = '';
        this.i_IdTipoInmueble = 0;
        this.i_IdGrupoEmpresarial = 0;
        this.s_Operacion = '';
        this.i_IdPlaza = 0;
    }

    /**
     * **cf_Clone**
     *
     * Esta funcion no deberia de ser SobreEscrita.
     *
     * 	Clona el DTO (mismos atributos y estado), regresa un DTO de tipo InmuebleDTO.
     * @returns InmuebleDTO
     */
    public cf_Clone(): BaseDTO {
        let lDT_New: InmuebleDTO;

        lDT_New = new InmuebleDTO(null, null, false);
        lDT_New.e_CloneAttributes(this);

        return lDT_New;
    }

    /**
     * **cf_SamePK**
     *
     * Esta funcion no deberia de ser SobreEscrita.
     *
     * 	Revisa si la llave primaria de este DTO es igual a la del argumento (InmuebleDTO).
     * @param aDT_Entity El DTO contra el que se va a comparar la PK
     * @returns Boolean
     */
    public cf_SamePK(aDT_Entity: BaseDTO): Boolean {
        let lDT_Entity: InmuebleDTO;

        lDT_Entity = aDT_Entity as InmuebleDTO;

        if (this.i_IdRs === null || lDT_Entity.i_IdRs === null) return false;
        if (this.i_IdRs !== lDT_Entity.i_IdRs) return false;

        if (this.i_IdInmueble === null || lDT_Entity.i_IdInmueble === null) return false;
        if (this.i_IdInmueble !== lDT_Entity.i_IdInmueble) return false;

        return true;
    }

    /**
     * **e_CloneAttributes**
     *
     * Este evento podria ser SobreCargado (en caso de que se declaren variables en el DTO).
     *
     * 	Copia los valores de todos los atributos del DTO Original a este que es el Clon
     * @param aDTO_Original InmuebleDTO
     * @returns Nada
     */
    public e_CloneAttributes(aDTO_Original: BaseDTO): void {
        let lDTO_Original: InmuebleDTO;

        super.e_CloneAttributes(aDTO_Original);

        lDTO_Original = aDTO_Original as InmuebleDTO;

        this.i_IdRs = lDTO_Original.i_IdRs;
        this.i_IdInmueble = lDTO_Original.i_IdInmueble;
        this.s_Nombre = lDTO_Original.s_Nombre;
        this.s_NombreCorto = lDTO_Original.s_NombreCorto;
        this.i_IdTipoInmueble = lDTO_Original.i_IdTipoInmueble;
        this.i_IdGrupoEmpresarial = lDTO_Original.i_IdGrupoEmpresarial;
        this.s_Operacion = lDTO_Original.s_Operacion;
        this.i_IdPlaza = lDTO_Original.i_IdPlaza;
    }
}
