import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { formatDate } from '@angular/common';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HandleError, HttpErrorHandler } from './http-error-handler.service';
import { BaseDTO } from '../BaseClasses/Pattern/BaseDTO';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
@Injectable({
  providedIn: 'root',
})
export class HttpClientService {

  private is_Url = 'https://cloudservices.grupofrisa.com/ExpedienteElectronico/WebApi';
  private handleError: HandleError;
  private s_Token = '';
  constructor(
    private iHttp_Client: HttpClient,
    httpErrorHandler: HttpErrorHandler,
    @Inject(PLATFORM_ID) private platformId: string
  ) {
    this.handleError = httpErrorHandler.createHandleError('ServicesWebApi');
  }

  protected cf_GetHttpHeaders(): HttpHeaders {
    let lHeaders: HttpHeaders = new HttpHeaders();
    if (isPlatformBrowser(this.platformId)) {
      const ss_UsuarioNav = navigator.appVersion || navigator.userAgent;
      lHeaders = lHeaders.append('IdConsumidor', ss_UsuarioNav.toString());
    }
    lHeaders = lHeaders.append('Access-Control-Allow-Origin', '*');
    lHeaders = lHeaders.append('Access-Control-Allow-Methods', '*');
    lHeaders = lHeaders.append('Access-Control-Allow-Headers', '*');
    lHeaders = lHeaders.append('Content-Type', 'application/json');
    lHeaders = lHeaders.append('Accept', 'application/json');
    lHeaders = lHeaders.append(
      'IdTransaccion',
      'PTC-' + formatDate(Date(), 'HHmmss', 'en-US')
    );
    return lHeaders;
  }


    /**
   * **cf_GetListByAction**
   *
   * Recupera un arreglo para un **aEntityService** especifico
   *
   * @param aEntityService Entidad a la que pertenece el arreglo a recuperar
   * @param aAction Datos complementarios para cada elemento del Arreglo
   * @param aParameters Datos complementarios para filtrado u ordenación
   * @returns * **EntityServiceDTO[]**
   */
  public cf_GetListByAction(
    aEntityService: string,
    aAction: number,
    aParameters: any
  ): Observable<[]> {
    const lHeaders: HttpHeaders = this.cf_GetHttpHeaders();
    return this.iHttp_Client
      .post<[]>(
        this.is_Url + '/v1/GetList',
        {
          EntityService: aEntityService,
          Action: aAction,
          Parameters: aParameters,
        },
        { headers: lHeaders }
      )
      .pipe(catchError(this.handleError<[]>('GetList', null)));
  }

   /**
   * **cf_ReadPC**
   *
   * Recuperar cualquier DTO de un **aEntityService** especifico y el mismo DTO con su llave primaria
   *
   * @param aEntityService Entidad a la que pertenece el DTO a modificar
   * @param aDTORead El DTO a leer
   * @param aAction Accion especial para otros niveles del DTO (default = 0)
   * @param aOptions Datos para acciones o modificaciones especiales, normalmente son DTOs (default = null)
   * @returns * **EntityServiceDTO**
   */
  public cf_ReadPC<T>(
    aEntityService: string,
    aDTORead: BaseDTO,
    aAction: number = 0,
    aParameters: any = null
  ): Observable<T> {
    const lHeaders: HttpHeaders = this.cf_GetHttpHeaders();

    // tslint:disable-next-line:max-line-length
    return this.iHttp_Client
      .post<T>(
        this.is_Url + '/v1/Read',
        {
          EntityService: aEntityService,
          DtoRow: aDTORead,
          Action: aAction,
          Parameters: aParameters,
        },
        { headers: lHeaders }
      )
      .pipe(catchError(this.handleError<T>('Read', null)));
  }

    /**
   * **cf_GetListByAction**
   *
   * Recupera un arreglo para un **aEntityService** especifico
   *
   * @param aEntityService Entidad a la que pertenece el arreglo a recuperar
   * @param aAction Datos complementarios para cada elemento del Arreglo
   * @param aParameters Datos complementarios para filtrado u ordenación
   * @returns * **EntityServiceDTO[]**
   */
  public cf_GetListUtilerias(
    aEntityService: string,
    aAction: number,
    aParameters: any
  ): Observable<[]> {
    const lHeaders: HttpHeaders = this.cf_GetHttpHeaders();
    return this.iHttp_Client
      .post<[]>(
        this.is_Url + '/v1/Utilerias/GetList',
        {
          EntityService: aEntityService,
          Action: aAction,
          Parameters: aParameters,
        },
        { headers: lHeaders }
      )
      .pipe(catchError(this.handleError<[]>('GetList', null)));
  }

  public cf_ReadPoligono<T>(
    aPlaza: number,
    aIdPoligono: number,
  ): Observable<T> {
    const lHeaders: HttpHeaders = this.cf_GetHttpHeaders();
    return this.iHttp_Client
      .post<T>(
        this.is_Url + '/v1/ReadPoligono',
        {
          i_IdPlaza: aPlaza,
          i_TarjetaIdPoligono: aIdPoligono,
        },
        { headers: lHeaders }
      )
      .pipe(catchError(this.handleError<T>('ReadPoligono', null)));
  }

 
  public cf_SubmitForm(
    aDatos: string,
  ): Observable<[]> {
    const lHeaders: HttpHeaders = this.cf_GetHttpHeaders();
    return this.iHttp_Client
      .post<[]>(
        this.is_Url + '/v1/SubmitContacto',
        aDatos,
        { headers: lHeaders }
      )
      .pipe(catchError(this.handleError<[]>('SubmitAutoRegistro', null)));
  }

}
