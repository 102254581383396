import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ImagenDTO } from '../../../Common/Models/ImagenDTO';
import { HttpClientService } from '../../../Common/Servicios/http-client.service';
import {  isPlatformBrowser } from '@angular/common';
import { SeoService } from '../../servicios/seo.service';
import { PlantillaDTO } from '../../../Common/Models/PlantillaDTO';

@Component({
  selector: 'app-modal-evento',
  templateUrl: './modalevento.html',
  styleUrls: ['./modalevento.css']
})
export class ModalEventoComponent implements OnInit {
  aDTO_Imagen: ImagenDTO;
  aDTO_Plantilla: PlantillaDTO = new PlantillaDTO();
  href: string;
  s_TextoMsj = 'Ven y Disfruta con tu familia visitando nuestros diferentes eventos en mundo E';
  s_Tags = 'MundoE,Frisa,eventos';
  base_Href: string = '';
  s_Tipo = '';
  constructor(
    public dialogRef: MatDialogRef<ModalEventoComponent>,
    private ats_HttpCms: HttpClientService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(PLATFORM_ID) private platformId: Object,
    private ats_SEO: SeoService,
  ) {
  }

  ngOnInit() {
    this.aDTO_Imagen = new ImagenDTO(this.data.i_IdSeccion);
    this.s_Tipo = this.data.s_Tipo;
    this.base_Href = this.ats_SEO.cf_GetUrl();
    this.cf_Read();
    if (isPlatformBrowser(this.platformId)) {
      this.href = `${window.location.protocol}//${window.location.host}${window.location.pathname}/${this.aDTO_Imagen.i_IdImagen}`;
    }
  }

  cf_Read() {
    this.ats_HttpCms.cf_ReadPC('ImagenES', this.aDTO_Imagen).subscribe(Item => {
      if (Item !== null) {
        this.aDTO_Imagen = Item as ImagenDTO;
        if (isPlatformBrowser(this.platformId)) {
          document.querySelector('mat-dialog-container').scrollTop = 0;
        }
        const s_ImagenRuta = `${this.base_Href}${this.aDTO_Plantilla.s_CeoReservado3}`;
        this.ats_SEO.cf_SEO(this.href,
          this.aDTO_Plantilla.s_CeoReservado1,
          s_ImagenRuta, this.aDTO_Plantilla.s_CeoReservado2);
      }
    });
  }

  cf_Facebook( s = 'script', id = 'datos_estruc') {
    if (isPlatformBrowser(this.platformId)) {
      let js, fjs = document.getElementsByTagName(s)[0];
      if (document.getElementById(id)) { return; }
      js = document.createElement(s);
      js.id = id;
      fjs.parentNode.insertBefore(js, fjs);
    }
  }

  // cf_enlace(){
  //   window.open(this.aDTO_Imagen.s_UrlValor, '_blank');
  //   console.log('enlace', this.aDTO_Imagen.s_UrlValor);
  // }
}
