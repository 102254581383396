import { Component, OnInit, ViewChild, ElementRef, OnDestroy, Input, AfterViewChecked, DoCheck, AfterViewInit, Inject, PLATFORM_ID, OnChanges } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';
import { HttpClientService } from '../Common/Servicios/http-client.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-planos',
  templateUrl: './planos.component.html',
  styleUrls: ['./planos.component.css'],
})
export class PlanosComponent implements OnInit, OnDestroy, OnChanges {

  @ViewChild('contenedorSVG') svgplano: ElementRef;
  @ViewChild('contenedorInfoLocal') ER_InfoLocal: ElementRef;
  @Input() s_IdLocal: string;
  @Input() s_RutaPlano: string = '';
  @Input() s_Imagen: string = '';
  @Input() s_NombreComercial: string = '';
  @Input() s_NombreLocal: string = '';
  @Input() b_LocalEx: boolean = false;
  @Input() s_NombrePlano = '';
  s_HTMLSvg = '';
  s_Rectangulo = '';
  html_SvgSanitizado: any;
  htmlInfoLocal: any = null;
  s_InfoLocal = '';
  constructor(
    private iHttp_Client: HttpClient,
    private ats_DomSanitizer: DomSanitizer,
    @Inject(PLATFORM_ID) private platformId: Object,
    private ats_HttpCms: HttpClientService,
  ) {
    if (isPlatformBrowser(this.platformId)) {
      (<any>window).cf_Recuperalocal = this.cf_Recuperalocal.bind(this);
      (<any>window).cf_Cerrar = this.cf_Cerrar.bind(this);
    }
  }

  ngOnInit() {
  }

  ngOnChanges() {
    if (this.s_RutaPlano !== '') {
      this.s_InfoLocal = '';
      this.ER_InfoLocal.nativeElement.innerHTML = '';
      this.htmlInfoLocal = this.ats_DomSanitizer.bypassSecurityTrustHtml(this.s_InfoLocal);
      this.cf_ObtainSvg(this.s_RutaPlano);
    }
  }

  ngOnDestroy() {
    if (isPlatformBrowser(this.platformId)) {
      delete (<any>window).cf_InfoLocal;
      delete (<any>window).cf_Cerrar;
    }
  }

  cf_ObtainSvg(as_RutaPlano: string) {
    if (as_RutaPlano !== '') {
      const headers = new HttpHeaders({
        'Content-Type': 'text/plain',
      });
      const request = this.iHttp_Client
        .get(as_RutaPlano, {
          headers: headers,
          responseType: 'text',
        })
        .subscribe((res) => {
          this.s_HTMLSvg = res;
          const doc = new DOMParser().parseFromString(this.s_HTMLSvg, 'text/html');
          doc.getElementsByTagName('svg')[0].setAttribute('easypz',
           `{"applyTransformTo": "svg > *", "modes": ["SIMPLE_PAN", "WHEEL_ZOOM", "PINCH_ZOOM"],
           "options": { "minScale": "0.9", "maxScale": "2.5", "bounds": { "top": -500, "right": 1000, "bottom": 500, "left": -1000}} } `);
          this.s_HTMLSvg = doc.documentElement.innerHTML;
          this.html_SvgSanitizado = this.ats_DomSanitizer.bypassSecurityTrustHtml(this.s_HTMLSvg);
          if (this.s_IdLocal !== null && this.s_IdLocal !== '' && this.s_IdLocal !== undefined) {
            this.cf_Recuperalocal(this.s_IdLocal, this.b_LocalEx);
          }
        }
          , (error) => { console.log('error al recuperar svg', error); }
        );
    }
  }

  makeTrustedHTML(s_HTML) {
    return this.ats_DomSanitizer.bypassSecurityTrustHtml(s_HTML);
  }

  cf_Recuperalocal(s_IdLocal: any, blocal?) {
    this.ER_InfoLocal.nativeElement.innerHTML = '';
    this.htmlInfoLocal = this.ats_DomSanitizer.bypassSecurityTrustHtml(this.s_InfoLocal);
    if (blocal) {
      s_IdLocal = s_IdLocal.toString();

      if (isPlatformBrowser(this.platformId)) {
        setTimeout(() => {
          const doc = new DOMParser().parseFromString(this.s_HTMLSvg, 'text/html');
          const clases = doc.querySelectorAll('.local-seleccionado');
          [].forEach.call(clases, function (el) {
            try {
              el.classList.remove('local-seleccionado');
            } catch (error) {
              console.log(error);
            }
          });
          const evento: any = document.getElementById(s_IdLocal) as any;
          if (evento !== null && evento !== undefined) {
            doc.getElementById(s_IdLocal).classList.add('local-seleccionado');
            const bbox = evento.getBBox();
            const s_Image = this.s_Imagen;
            const ls_Url = '';
            const ls_NombreComercial = this.s_NombreComercial;
            const ls_NombreLocal = this.s_NombreLocal;
            this.s_Rectangulo = `
          <g>
          <foreignObject height="72" width="40" x="${(bbox.x + (bbox.width / 2)) - 20}" y="${(bbox.y + (bbox.height / 2)) - 82}">
              <image class="flecha-plano"  src="assets/svgs/flecha.png"  />
            </foreignObject></g>`;
            this.s_InfoLocal = `
            <div class="cerrar-info" onclick="cf_Cerrar()"><i class="fas fa-times-circle"></i></div>
            <div class="col-md-4 p-0 m-0">
            <img src="${s_Image}" class="img-fluid">
        </div>
        <div class="col-md-8">
            <p><b>${ls_NombreComercial}</b>
                <br>${ls_NombreLocal}
            </p>
        </div>`;
            // <rect width="180" height="95" x="${x}" y="${y}"  rx="9" fill="white" stroke="#585858" stroke-opacity="0.9"; stroke-width="1"/>
            // <text fill="red" x="${x + 165}" y="${y + 10}" style="font-size:12px; cursor:pointer;" onclick="cf_Cerrar()">X</text>
            // <image x="${x + 3}" y="${y + 10}" href="${s_Image}" height="78" width="78" />`;
            //   this.s_Rectangulo += `<foreignObject  x="${x + 87}" y="${y + 15}" width="90" height="95" >
            //   <p class="texto-svg">
            //   ${ls_NombreComercial}
            //   <br>
            //   ${ls_NombreLocal}
            //   <br>`;
            //   this.s_Rectangulo += ` </p>
            // </foreignObject>`;
            //   this.s_Rectangulo += '</g>';
            setTimeout(() => {
              doc.getElementById('INICIO_x5F_TABLA').innerHTML = this.s_Rectangulo;
              this.s_HTMLSvg = doc.documentElement.innerHTML;
              this.svgplano.nativeElement.innerHTML = this.s_HTMLSvg;
              this.ER_InfoLocal.nativeElement.innerHTML = this.s_InfoLocal;
              this.html_SvgSanitizado = this.ats_DomSanitizer.bypassSecurityTrustHtml(this.s_HTMLSvg);
              this.htmlInfoLocal = this.ats_DomSanitizer.bypassSecurityTrustHtml(this.s_InfoLocal);
            }, 200);
          } else {
            this.s_InfoLocal = '';
            this.s_HTMLSvg = '';
            this.s_NombrePlano = '';
            this.ER_InfoLocal.nativeElement.innerHTML = this.s_InfoLocal;
            this.html_SvgSanitizado = this.ats_DomSanitizer.bypassSecurityTrustHtml(this.s_HTMLSvg);
            this.htmlInfoLocal = this.ats_DomSanitizer.bypassSecurityTrustHtml('');
          }
        }, 300);
      }
    } else {
      this.cf_RecuperaInformacion(Number(s_IdLocal));
    }
    return true;
  }

  cf_RecuperaInformacion(i_IdPoligono: number) {
    this.ER_InfoLocal.nativeElement.innerHTML = '';
    this.htmlInfoLocal = this.ats_DomSanitizer.bypassSecurityTrustHtml(this.s_InfoLocal);
    this.ats_HttpCms.cf_ReadPoligono(environment.i_IdPlaza, i_IdPoligono).subscribe(Item => {
      if (Item !== null) {
        const lDTOTarjeta = Item as any;
        const s_IdLocal = i_IdPoligono.toString();

        if (isPlatformBrowser(this.platformId)) {
          setTimeout(() => {
            const doc = new DOMParser().parseFromString(this.s_HTMLSvg, 'text/html');
            const clases = doc.querySelectorAll('.local-seleccionado');
            [].forEach.call(clases, function (el) {
              try {
                el.classList.remove('local-seleccionado');
              } catch (error) {
                console.log(error);
              }
            });
            const evento: any = document.getElementById(s_IdLocal) as any;
            if (evento !== null && evento !== undefined) {
              doc.getElementById(s_IdLocal).classList.add('local-seleccionado');
              const bbox = evento.getBBox();
              const s_Image = lDTOTarjeta.s_Imagen_Ubicacion + '/' + lDTOTarjeta.s_Imagen_Nombre;
              let ls_Url = '';
              switch (lDTOTarjeta.i_Plantilla_IdTipoPlantilla) {
                case 11:
                  ls_Url = `/tienda/${lDTOTarjeta.s_Plantilla_UrlRelativa}`;
                  break;
                case 13:
                  ls_Url = `/tiendaC/${lDTOTarjeta.s_Plantilla_UrlRelativa}`;
                  break;
                case 15:
                  break;
                default:
                  break;
              }
              const ls_NombreComercial = lDTOTarjeta.s_TarjetaTitulo;
              const ls_NombreLocal = lDTOTarjeta.s_TarjetaDescripcionInferior;
              const s_rectangulo = `
            <g>
            <foreignObject height="72" width="40"  x="${(bbox.x + (bbox.width / 2)) - 20}" y="${(bbox.y + (bbox.height / 2)) - 82}">
              <image class="flecha-plano"  src="assets/svgs/flecha.png"  />
            </foreignObject></g>`;
              this.s_InfoLocal = `
            <div class="cerrar-info" onclick="cf_Cerrar()"><i class="fas fa-times-circle"></i></div>
            <div class="col-md-4 p-0 m-0">
            <img src="${s_Image}" class="img-fluid">
            </div>
            <div class="col-md-8">
            <p><b>${ls_NombreComercial}</b>
                <br>${ls_NombreLocal}
                <br>`;
              if (lDTOTarjeta.s_Plantilla_UrlRelativa !== '' && lDTOTarjeta.s_Plantilla_UrlRelativa !== null) {
                this.s_InfoLocal += `<a href="${ls_Url}">Ver más… </a>`;
              }
              this.s_InfoLocal += `</p>
               </div>`;
              // <rect width="180" height="95" x="${x}" y="${y}"  rx="9" fill="white" stroke="#585858" stroke-opacity="0.9"; stroke-width="1"/>
              // <text fill="red" x="${x + 165}" y="${y + 10}" style="font-size:12px; cursor:pointer;" onclick="cf_Cerrar()">X</text>
              // <image x="${x + 3}" y="${y + 10}" href="${s_Image}" height="78" width="78" />`;
              // s_rectangulo += `<foreignObject  x="${x + 87}" y="${y + 15}" width="90" height="95" >
              //                  <p class="texto-svg">
              //                  ${ls_NombreComercial}
              //                  <br>
              //                  ${ls_NombreLocal}
              //                  <br>`;
              // if (lDTOTarjeta.s_Plantilla_UrlRelativa !== '' && lDTOTarjeta.s_Plantilla_UrlRelativa !== null) {
              //   s_rectangulo += `<a href="${ls_Url}">Ver más… </a>`;
              // }
              // s_rectangulo += ` </p>
              //                </foreignObject>`;
              //  s_rectangulo += '</g>';
              setTimeout(() => {
                doc.getElementById('INICIO_x5F_TABLA').innerHTML = s_rectangulo;
                this.s_HTMLSvg = doc.documentElement.innerHTML;
                this.svgplano.nativeElement.innerHTML = this.s_HTMLSvg;
                this.ER_InfoLocal.nativeElement.innerHTML = this.s_InfoLocal;
                this.html_SvgSanitizado = this.ats_DomSanitizer.bypassSecurityTrustHtml(this.s_HTMLSvg);
                // console.log(this.s_InfoLocal);
                this.htmlInfoLocal = this.ats_DomSanitizer.bypassSecurityTrustHtml(this.s_InfoLocal);
                // console.log(this.htmlInfoLocal);
              }, 100);
            }
          }, 200);
        }
      } else {
        if (isPlatformBrowser(this.platformId)) {
          setTimeout(() => {
            const doc = new DOMParser().parseFromString(this.s_HTMLSvg, 'text/html');
            const clases = doc.querySelectorAll('.local-seleccionado');
            [].forEach.call(clases, function (el) {
              try {
                el.classList.remove('local-seleccionado');
              } catch (error) {
                console.log(error);
              }
            });
            let s_rectangulo = `<g>`;
            s_rectangulo += '</g>';
            setTimeout(() => {
              doc.getElementById('INICIO_x5F_TABLA').innerHTML = s_rectangulo;
              this.s_HTMLSvg = doc.documentElement.innerHTML;
              this.s_InfoLocal = '';
              this.svgplano.nativeElement.innerHTML = this.s_HTMLSvg;
              this.ER_InfoLocal.nativeElement.innerHTML = this.s_InfoLocal;
              this.html_SvgSanitizado = this.ats_DomSanitizer.bypassSecurityTrustHtml(this.s_HTMLSvg);
              this.htmlInfoLocal = this.ats_DomSanitizer.bypassSecurityTrustHtml('');
            }, 100);
          }, 100);
        }
      }
    });
  }

  cf_Cerrar() {
    if (isPlatformBrowser(this.platformId)) {
      const doc = new DOMParser().parseFromString(this.s_HTMLSvg, 'text/html');
      const clases = doc.querySelectorAll('.local-seleccionado');
      [].forEach.call(clases, function (el) {
        try {
          el.classList.remove('local-seleccionado');
        } catch (error) {
          console.log(error);
        }
      });
      let s_rectangulo = `<g>`;
      s_rectangulo += '</g>';
      setTimeout(() => {
        doc.getElementById('INICIO_x5F_TABLA').innerHTML = s_rectangulo;
        this.s_HTMLSvg = doc.documentElement.innerHTML;
        this.svgplano.nativeElement.innerHTML = this.s_HTMLSvg;
        this.ER_InfoLocal.nativeElement.innerHTML = '';
        this.html_SvgSanitizado = this.ats_DomSanitizer.bypassSecurityTrustHtml(this.s_HTMLSvg);
        this.htmlInfoLocal = this.ats_DomSanitizer.bypassSecurityTrustHtml(this.s_InfoLocal);
      }, 100);
    }
  }


}
