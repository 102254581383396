import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { HttpClientService } from '../Common/Servicios/http-client.service';
import { SitioWebDTO } from '../Common/Models/SitioWebDTO';
import { isPlatformBrowser } from '@angular/common';
import { environment } from '../../environments/environment';
import { SeoService } from '../Generales/servicios/seo.service';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { ImagenDTO } from '../Common/Models/ImagenDTO';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-principal',
  templateUrl: './principal.component.html'
})
export class PrincipalComponent implements OnInit {

  aDTO_SitioWeb = new SitioWebDTO(environment.i_IdSitioweb);
  aDTOImagen = new ImagenDTO(1);
  hreffoo: any;
  base_Href: string;
  s_TextoMsj = environment.s_TextoMsj;
  s_Tags = environment.s_Tags;
  s_UrlFacebookFrame: any;
  s_JsonLd: string;
  html_Ubicacion: any;
  html_Facebook: any;
  html_Youtube: any;
  html_Instagram: any;
  s_Latitud: string;
  s_Longitud: string;
  html_LinkMapa: any;
  html_Informacion: any;
  s_Logo = '';
  s_AcercaDe: string;
  b_NavActual: boolean;
  b_Aviso = true;
  a_Menu  = environment.a_Menu;
  a_footer = environment.a_MenuFooter;
  constructor(
    private title: Title,
    private ats_HttpCms: HttpClientService,
    private ats_SEO: SeoService,
    private ats_Router: Router,
    @Inject(PLATFORM_ID) private platformId: string,
    private ats_DomSanitizer: DomSanitizer) {
    this.cf_Read();
    this.base_Href = this.ats_SEO.cf_GetUrl();
    this.hreffoo = this.base_Href + this.ats_Router.url;
    this.title.setTitle(environment.s_NombreSitio);
  }

  ngOnInit() {
    // console.log(this.a_Menu.length);
    this.hreffoo = this.base_Href + this.ats_Router.url;
    this.html_Ubicacion = this.ats_DomSanitizer.bypassSecurityTrustHtml(environment.s_UbicacionFooter);
    this.html_Facebook = this.ats_DomSanitizer.bypassSecurityTrustHtml(environment.s_RedSocialFacebook);
    this.html_Youtube = this.ats_DomSanitizer.bypassSecurityTrustHtml(environment.s_RedSocialYoutube);
    this.html_Instagram = this.ats_DomSanitizer.bypassSecurityTrustHtml(environment.s_RedSocialInstagram);
    this.html_LinkMapa = this.ats_DomSanitizer.bypassSecurityTrustHtml(environment.s_MapaLink);
    this.s_AcercaDe = environment.s_AcercaDe;
    this.s_Latitud = environment.s_Lat;
    this.s_Longitud = environment.s_Lng;
    if (isPlatformBrowser(this.platformId)) {
       this.b_NavActual = this.cf_DetectaNav();
    }

  }

  onActivate() {
    this.s_JsonLd = '';
    if (isPlatformBrowser(this.platformId)) {
      document.querySelector('mat-sidenav-content').scrollTop = 0;
      this.hreffoo = this.base_Href + this.ats_Router.url;
    }
  }


  cf_Read() {
    this.ats_HttpCms.cf_ReadPC('SitioWebES', this.aDTO_SitioWeb).subscribe(Item => {
      if (Item !== null) {
        this.aDTO_SitioWeb = Item as SitioWebDTO;
        if (isPlatformBrowser(this.platformId)) {
          sessionStorage.setItem('sitio', JSON.stringify(this.aDTO_SitioWeb));
        }
        this.aDTOImagen = new ImagenDTO(this.aDTO_SitioWeb.i_IdImagenLogoPrincipal);
        this.cf_ReadImagen();
      }
    });
  }

  cf_ReadImagen() {
    this.ats_HttpCms.cf_ReadPC('ImagenES', this.aDTOImagen).subscribe(
      Item => {
        if (Item !== null) {
          this.aDTOImagen = Item as ImagenDTO;
          this.s_Logo = this.aDTOImagen.s_Ubicacion + '/' + this.aDTOImagen.s_Nombre;
        }
      }
    );
  }


  cf_DetectaNav(): boolean {
    if (isPlatformBrowser(this.platformId)) {
      const IExplorer = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
      const isEdge = /Edg/i.test(window.navigator.userAgent);
      const isFirefox = /Firefox/i.test(window.navigator.userAgent);
      const isOpera = /OPR/i.test(window.navigator.userAgent);
      const Safari = /Safari/i.test(window.navigator.userAgent);
      const Chrome = /Chrome/i.test(window.navigator.userAgent);
      if (IExplorer) {
        return true;
      } else if (isEdge) {
        let a_getVersion = window.navigator.userAgent.split('Edg/');
        a_getVersion = a_getVersion[1].split(' ');
        const s_NoVersion = a_getVersion[0];
        const a_Version = s_NoVersion.split('.');
        const n_Version = Number(a_Version[0]);
        const isActual = n_Version >= 80 ? true : false;
        if (isActual) {
          return false;
        } else {
          return true;
        }
      }
      // else if (isFirefox) {
      //   let a_getVersion = window.navigator.userAgent.split('Firefox/');
      //   a_getVersion = a_getVersion[1].split(' ');
      //   const s_NoVersion = a_getVersion[0];
      //   const a_Version = s_NoVersion.split('.');
      //   const n_Version = Number(a_Version[0]);
      //   const isActual = n_Version >= 81 ? true : false;
      //   if (isActual) {
      //   } else {
      //   }
      // } else if (isOpera) {
      //   let a_getVersion = window.navigator.userAgent.split('OPR/');
      //   a_getVersion = a_getVersion[1].split(' ');
      //   const s_NoVersion = a_getVersion[0];
      //   const a_Version = s_NoVersion.split('.');
      //   const n_Version = Number(a_Version[0]);
      //   const isActual = n_Version >= 70 ? true : false;
      //   if (isActual) {
      //     return false;
      //   } else {
      //     return true;
      //   }
      // } else if (Chrome) {
      //   let a_getVersion = window.navigator.userAgent.split('Chrome/');
      //   a_getVersion = a_getVersion[1].split(' ');
      //   const s_NoVersion = a_getVersion[0];
      //   const a_Version = s_NoVersion.split('.');
      //   const n_Version = Number(a_Version[0]);
      //   const isActual = n_Version >= 85 ? true : false;
      //   if (isActual) {
      //     return false;
      //   } else {
      //     return true;
      //   }
      // } else if (Safari) {
      //   let a_getVersion = window.navigator.userAgent.split('Safari/');
      //   a_getVersion = a_getVersion[1].split(' ');
      //   const s_NoVersion = a_getVersion[0];
      //   const a_Version = s_NoVersion.split('.');
      //   const n_Version = Number(a_Version[0]);
      //   const isActual = n_Version >= 607 ? true : false;
      //   if (isActual) {
      //     return false;
      //   } else {
      //     return true;
      //   }
      // }
      else {
        return false;
      }
    }
  }

}
